import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-otpinputui',
  templateUrl: './otpinputui.component.html',
  styleUrls: ['./otpinputui.component.css']
})
export class OtpinputuiComponent implements OnInit {
  @Input() browsername:number;

  constructor() { }

  ngOnInit() {
  }

  // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 10 nov 2021 start -->
  automaticmoveonestep(i, event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
      if (i == 1) {
        // $("#second").focus();
        document.getElementById('second').focus();

      }
      else if (i == 2) {
        // $("#third").focus();
        document.getElementById('third').focus();

      }
      else if (i == 3) {
        // $("#fourth").focus();
        document.getElementById('fourth').focus();


      }
      else {

      }


    }
    else {
      return false
    }

    // if (i == 4) {
    //   if (event.code == "Backspace") {
    //     $("#third").focus();
    //   }
    // }


  }
  // ...end...

}
