import { Component, OnInit,HostListener } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Globals } from '../globals';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
  
export class NotificationComponent implements OnInit {
  notifications=[{'notification_data':'<b>Hello call us : </b><a href="tel:+62896706255135">9894349067</a>&nbsp;<img src="https://i.pinimg.com/originals/9c/b0/70/9cb070d62dc738a0c3a1a408d68e4af5.jpg">'}];
  totalcount:any;
  flag:number=1;

  constructor(private globals: Globals,private storage: SessionStorageService,private authservice: AuthService ,private router: Router,private spinner: NgxSpinnerService) { }
  
  @HostListener("window:scroll", ["$event"])

  onWindowScroll(e) {
  //In chrome and some browser scroll is given to body tag
  let pos = document.documentElement.scrollTop  + document.documentElement.offsetHeight;
    let pos1 = e.target['scrollingElement'].scrollTop + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    let max1 = document.body.scrollHeight-10;
    //console.log(Math.round(pos) +" == "+ max +" and "+ Math.round(pos1) +" == "+ max1)
    if(Math.round(pos) == max || Math.round(pos1) == max1 || Math.round(pos1) > max1)   {
      if(this.notifications.length < this.totalcount ){
        this.getnotification(this.flag)
      }
    }
  }

  getnotification(flag){
    this.authservice.apiget('getNotification/?flag='+flag).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }

      else if(response.status == 403){
        this.storage.store("islogged", 0);
        this.storage.clear("token");
       this.router.navigate(['/homepage/login']); //Added by Kunal on 13 march 2020 
      }
    
      else if (response.status == 200) {
    
        if(flag==1){
          this.notifications = response.response
          this.totalcount = response.total_count
        }
        else{
          this.notifications = this.notifications.concat(response.response)
        }
        this.globals.notificationcount = 0;
        this.flag=this.flag+1
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  ngOnInit() {
    this.getnotification(1);
  }

  delete(id) {
    Swal.fire({
      title: 'Are you sure you want to Delete this notification ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'YES',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.authservice.apidelete('DeleteNotification/', '?notification_id='+id).subscribe((res: any) => {
          let response = res.json();
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            Swal.fire(response.message)
          }
          else if (response.status == 200) {
            this.ngOnInit();
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("Connect to the Internet")
          }
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return false;
      }
    })

  }

}