import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-commonhomepagesort',
  templateUrl: './commonhomepagesort.component.html',
  styleUrls: ['./commonhomepagesort.component.css']
})
export class CommonhomepagesortComponent implements OnInit {
  @Output() commonfiltersort= new EventEmitter<any>();

  constructor(private modalService:NgbModal) { }

  ngOnInit() {
  }

  closeall() {
    this.modalService.dismissAll()
  }


  sortapi(a:number,b:number){
this.commonfiltersort.emit({value1:a,value2:b})
  }
}
