import { Component, OnInit, ViewEncapsulation, HostListener, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { Http, Response, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { SessionStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import { Options, LabelType } from 'ng5-slider';
import Swal from 'sweetalert2';
import { SharedService } from '../Services/shared.service';



@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ProductListingComponent implements OnInit {
  logged: any = 0;
  tab: number = 0;
  selectedbrand = [];
  selectedcolor = [];
  selectedmemory = [];
  selectedstorage = [];
  selectedbattery = [];
  selecteddisplay = [];
  selectedItems = [];
  selectedItems2 = [];
  selectedItems3 = [];
  dropdownSettings = {};
  dropdownSettings2 = {};
  dropdownSettings3 = {};
  selectedbrandid = '';
  isbranded: number = 0;
  isfiltered: number = 0;
  issortedtype: number = 10;
  banners: any = [];


  device: number = 0;
  brands: any = [];

  setfilterflag: number = 0;
  sidedraweractivate: number = 0;
  sidedrawerresponse: any = [];
  sidedrawerbanner: any = [];

  // added by Kunal bansal start on 02 july 2020
  category: any = [];
  subcategory: any = []
  categorylevel3: any = []
  subcategoryactive: number = 0;
  // end
  ismobile: number = 0;
  selectedhighrange: number;
  selectedlowrange: number;
  selllist: any = [];
  buylist: any = [];
  recentlyviewed: any = [];
  filterdata: any = [];
  realoffset: number = 1
  realmaxload: number;
  selloffset: number = 1;
  buyoffset: number = 1;
  maxbuy: number;
  maxsell: number;
  filter: number = 1;
  checkcategoryimage: number = 0;

  selectedFilters = [];


  filter1 = [];
  filter2 = [];
  filter3 = [];
  brands1 = [];
  brands2 = [];
  brands3 = [];
  brands4 = [];
  brands5 = [];
  brands6 = [];
  sidecategoryfilters1 = [];
  sidecategoryfilters2 = [];
  sidecategoryfilters3 = [];
  sidecat1: any = [];
  sidecat2: any = [];
  sidecat3: any = [];
  setfilactive: number = 0;
  homePageactive: number = 0;
  catid: number;
  checkbannerlength: number;

  firstlevel = [];
  secondlevel = [];

  filtersortfirst = [];
  filtersortsecond = [];
  filtersortthird = [];

  locationon: number = 0;
  googleapiresult: any = [];
  District: string;
  State: string;
  // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
  countrylocation: string;

  // ...end...

  // <!-- phase 6 module show category like brand logo on top added by kunal bansal on 13 april 2022 start -->

  categoryiconlist: any = [];
  selectedcategoryname: string = 'Mobiles';

  addresslength: number;

  options: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min price:</b>' + value;
        case LabelType.High:
          return '<b>Max price:</b>' + value;
        default:
          return '' + value;
      }
    }
  };

  // <!-- added by KUnal bansal on 04 may 2022 start -->
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  bannertimingarray: any = [];
  activebannerindex: number = 0;

  // phase 6 popup banner added by kunal bansal on 12 may 2022 start
  popupimage: string = "";
  popupimageweb: string = "";
  popupimageapp: string = "";
  actiontype: number;
  bannerimage: string = "";
  bannersubimage: string = "";
  bannerid: number;
  disablepopup: number = 3;
  // phase 6 added by kunal bansal on 20 may 2022 to style scrollbar height b/w firebox and other browsers start
  scrollheight: string = "90px";
  defaultProductCategory: number = 0;

  constructor(private sharedService:SharedService,private http: Http, private storage: SessionStorageService, lc: NgZone, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService, private modalService: NgbModal) {

    window.onscroll = () => {
      let status = "not reached";
      let windowHeight = "innerHeight" in window ? window.innerHeight
        : document.documentElement.offsetHeight;
      let body = document.body, html = document.documentElement;
      let docHeight = Math.max(body.scrollHeight,
        body.offsetHeight, html.clientHeight,
        html.scrollHeight, html.offsetHeight);
      let windowBottom = windowHeight + window.pageYOffset;
      if (Math.round(windowBottom) >= docHeight) {
        if (window.location.pathname == "/homepage/productdetail" || window.location.pathname == "/homepage/cart" || window.location.pathname == "/homepage/brandlist" || window.location.pathname == "/homepage/profile") {

        }
        else if (window.location.pathname.includes('newhome')) {

        }
        else {

          if ((this.tab == 0 && this.realmaxload > this.buylist.length) || (this.tab == 1 && this.realmaxload > this.selllist.length)) {

            this.buysellchange(this.tab, this.realoffset)
          }
        }
      }
      lc.run(() => {

      });
    };
  }
  // commented by Kunal bansal on 12 jan 2021 to fix sort by issue
  // @HostListener("window:scroll", ["$event"])

  // onWindowScroll(e) {
  //   let pos = document.documentElement.scrollTop + document.documentElement.offsetHeight;
  //   let pos1 = e.target['scrollingElement'].scrollTop + document.documentElement.offsetHeight;
  //   let max = document.documentElement.scrollHeight;
  //   let max1 = document.body.scrollHeight - 10;

  //   if (Math.round(pos) == max) {
  //     if ((this.tab == 0 && this.realmaxload > this.buylist.length) || (this.tab == 1 && this.realmaxload > this.selllist.length)) {
  //       this.buysellchange(this.tab, this.realoffset)

  //     }
  //   }
  // }
  // if(Math.round(pos) == max || Math.round(pos1) == max1 || Math.round(pos1) > max1) modify this line above to fix pagination wrong offset hit issue on 21/04/2020 by Kunal bansal
  ngOnInit() {
    // phase 6 added by kunal bansal on 20 may 2022 to style scrollbar height b/w firebox and other browsers start
    let browsername = navigator.userAgent;
    if (browsername.includes('Firefox')) {
      this.scrollheight = "90px";
    }
    else {
      this.scrollheight = "75px";
    }
    // ....end.....
    // get user current location function and api added by KUnal bansal on 23 june 2021 start
    this.logged = this.storage.retrieve('islogged');
    if (this.logged == 1) {
      this.authservice.apiget('Profile/?flag=1', 1).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {

          sessionStorage.setItem('addresslength', response.response.Address[2].address_list.length)
          // phase 6 added by kunal bansal on 21 april 2022 start
          this.addresslength = Number(sessionStorage.getItem('addresslength'));
          this.getcurrentlocation();
        }
        // logout of all devices when change password module added by Kunal bansal on 18jan2021
        else if (response.status == 401) {

          Swal.fire("That Account Old password has been changed. You have been signed out now.")
        }
        else { Swal.fire("Server Down") }
      }

        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    else {
      this.getcurrentlocation();
    }


    // ...end..

    // end

    // phase 6 added by kunal bansal on 21 april 2022 start
    this.storage.store('catid', 3);
    this.catid = 3;
    // ...end...

  }







  ngOnDestroy() {
    this.storage.clear('sidedraweractivate')
    this.storage.clear('sidecategoryfilter1')
    this.storage.clear('sidecategoryfilter2')
    this.storage.clear('sidecategoryfilter3')
    this.sidecategoryfilters1 = [];
    this.sidecategoryfilters2 = [];
    this.sidecategoryfilters3 = [];
  }
  onSelectAllfirst() {

    for (let k = 0; k < this.category.length; k++) {
      this.filtersortfirst.push({ 'id': this.category[k].id, 'name': this.category[k].category_name })
      this.firstlevel.push(this.category[k].id)
    }
    this.authservice.apipost('CategoryList/', {
      "category_id": this.firstlevel, "flag": "subcategory"

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.selectedItems2 = [];
        this.subcategory = response.response;
        this.subcategoryactive = 1;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

  }

  // added by Kunal bansal on 21 june 2021 start
  hithomepageapi() {
    // this.storage.store("pressedkey", 0); //added by Kunal on 03/16/2020
    this.tab = this.storage.retrieve('pressedkey')
    if (this.tab == null) {
      this.tab = 0;
      this.storage.store('categorytab', 0);
      this.storage.store('pressedkey', 0);

    }
    this.storage.clear('bannersearch');

    // Side drawer module added by Kunal bansal on 21 july 2020 start
    this.sidedraweractivate = this.storage.retrieve('sidedraweractivate');
    if (this.sidedraweractivate == 1 && this.tab == 0 || this.sidedraweractivate == 1 && this.tab == 1) {
      this.setfilterflag = 1;
      this.sidecat1 = this.storage.retrieve('sidecat1id');

      this.filtersortfirst = this.sidecat1;


      this.sidecat2 = this.storage.retrieve('sidecat2id');
      if (this.sidecat2.length > 0) {
        this.filtersortsecond = this.sidecat2;
      }
      this.sidecat3 = this.storage.retrieve('sidecat3id');
      if (this.sidecat3.length > 0) {
        this.filtersortthird = this.sidecat3;
      }
      this.sidecategoryfilters1 = this.storage.retrieve('sidecategoryfilter1')
      this.sidecategoryfilters2 = this.storage.retrieve('sidecategoryfilter2')
      this.sidecategoryfilters3 = this.storage.retrieve('sidecategoryfilter3')


      this.buylist = this.storage.retrieve('sidedrawer');
      this.banners = this.storage.retrieve('sidedrawerbanner');
      this.brands = this.storage.retrieve('sidedrawerbrands');
      this.spinner.hide();

    }
    else {
      this.logged = this.storage.retrieve('islogged');
      this.defaultapi(this.tab, 1);
    }
    // end
    setTimeout(() => {
      $('.carousel-control-next-icon').click();
    }, 4000);


    if (window.innerWidth < 400) {
      this.device = 1;
    }
    this.logged = this.storage.retrieve('islogged');

    if (window.screen.width <= 360) { // 768px portrait
      this.ismobile = 1;
    }
    this.dropdownSettings = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",
      labelKey: "value",
      primaryKey: "Id",
      badgeShowLimit: 4,
      autoPosition: false,
      position: "top",
      classes: "font_drop",
      maxHeight: 200
    };
    // Added by Kunal bansal on 2 july 2020 start
    this.dropdownSettings2 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",
      labelKey: "category_name",
      classes: "font_drop",
      position: 'top',
      autoPosition: false,

      maxHeight: 200
    };
    this.dropdownSettings3 = {
      singleSelection: true,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",
      labelKey: "category_name",
      classes: "font_drop",
      position: 'top',
      autoPosition: false,

      maxHeight: 200
    };
    // end


    $(document).ready(function () {

      $('.carousel-control-prev-icon').click();

      // this.activebannerindex==0?this.activebannerindex=this.banners.length-1:this.activebannerindex=this.activebannerindex-1
    }

    )




  }



  // end

  // geolocation(){
  //   this.authservice.geolocation().subscribe((res: any) => {
  //     let response = res.json();
  //    console.log(response)
  //   }
  //     , err => {
  //       this.spinner.hide();
  //       Swal.fire("Connect to the Internet")
  //     }
  //   )
  // }


  // get user current location function and api added by Kunal bansal on 23 june 2021 start
  getcurrentlocation() {

    this.getPosition().then(pos => {

      this.geocoding(`AIzaSyBvQhRMAEoYfqwjNkt1fhM12JZ71yMsD6o&latlng=${pos.lat},${pos.lng}`).subscribe((res: any) => {
        let response = res.json();
        // console.log(response);
        this.googleapiresult = response.results;
        for (let h = 0; h < this.googleapiresult[0].address_components.length; h++) {
          if (this.googleapiresult[0].address_components[h].types.includes("administrative_area_level_2")) {
            // lower case added by Kunal bansal on 05 july 2021 start
            this.District = this.googleapiresult[0].address_components[h].short_name.toLowerCase();
            this.storage.store("districtname", this.District)
            // end

          }
          if (this.googleapiresult[0].address_components[h].types.includes("administrative_area_level_1")) {
            this.State = this.googleapiresult[0].address_components[h].long_name;
          }
          // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          if (this.googleapiresult[0].address_components[h].types.includes("country")) {
            sessionStorage.setItem("phase5countrylocation", this.googleapiresult[0].address_components[h].long_name.toLowerCase());
            this.countrylocation = this.googleapiresult[0].address_components[h].long_name.toLowerCase();
          }
          // ...end...
          // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->

          // ...end...
        }




        // this.hithomepageapi();
        this.getdefaultCategoryId();


      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
      // this.Longitude=pos.lng;
      // this.latitude=pos.lat;
      // console.log('longitude : '+pos.lng+"  "+'latitude : '+pos.lat);
      // alert(this.Longitude);
      // alert(this.latitude);
    })
  }
  // end

  // get user current location function and api added by KUnal bansal on 21 june 2021 start
  getPosition(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.spinner.show();
      navigator.geolocation.getCurrentPosition(resp => {
        this.storage.store("locationstatus", 1);
        this.locationon = 1;
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });

      },
        err => {
          this.storage.store("locationstatus", 0);
          this.storage.store("districtname", "");
          // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          sessionStorage.setItem("phase5countrylocation", "");
          // ...end...
          // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          this.countrylocation = "";
          // ...end...
          this.locationon = 0;
          // Swal.fire('Please allow location to view Location specific inventory');

          // this.hithomepageapi();
          this.getdefaultCategoryId();

          // reject(err);

        });
    });


  }

  geocoding(url) {

    // var headers = new Headers();
    // var token = this.storage.retrieve('token');
    // headers.append('Content-Type', 'application/json');
    // headers.append('Token', token);
    return this.http.get("https://maps.googleapis.com/maps/api/geocode/json?key=" + url).pipe(map(
      (data: Response) => {
        this.spinner.hide();
        return data;
      }
    ))
  }

  // end


  onDeSelectAllfirst(item: any) {
    this.filtersortfirst = [];
    this.firstlevel = [];
    this.authservice.apipost('CategoryList/', {
      "category_id": [], "flag": "subcategory"

    })
      .subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          // Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.selectedItems2 = [];
          this.subcategory = response.response;
          this.subcategoryactive = 1;
        }
        else { Swal.fire("Server Down") }


      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )

  }

  // module added by KUnal bansal in case subcategory remove if category array empty 2 july 2020 start
  // OnItemDeSelect($event){
  //  this.subcategoryactive=0;
  //  this.selectedItems2=[];
  // }
  // end

  onSelectAllsecond(item: any) {
    for (let g = 0; g < this.subcategory.length; g++) {
      this.filtersortsecond.push({ 'id': this.subcategory[g].id, 'name': this.subcategory[g].category_name })
    }
  }

  onDeSelectAllsecond(item: any) {
    this.filtersortsecond = [];
  }



  // phase 6 defualt category listing added by kunal bansal on 18 april 2022 start
  categoryiconlisting() {
    this.authservice.apipost('homePageCategoryList/', {
      "platform": 0, //0 for web and 1 for   app
      "tab": this.tab,// 0 for buy and 1 for sell
      "offset": this.realoffset
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {

        // alert(JSON.stringify(response.response.banners[2].action_type))
        // this.realoffset = this.realoffset + 1;
        this.banners = response.response.banners;
        this.categoryiconlist = response.response.all_category_list;
       // if (this.categoryiconlist.length > 0) {

          // phase 6 added per client requirememt selected category remains at 1st in list added by kunal bansal on 21 april 2022 start
          // let hold;
          // this.categoryiconlist.forEach((element, index) => {
          //   if (element.category_name == 'Mobiles') {
          //     hold = this.categoryiconlist[index];
          //     this.categoryiconlist.splice(index, 1)
          //   }
          // });
          // this.categoryiconlist.unshift(hold)
          // // ...end....
        //}

        this.bannercountdown();

        // phase 6 popup banner added by kunal bansal on 12 may 2022 for responsiveness start
        if (response.response.pop_up_banner.length == 2) {
          response.response.pop_up_banner.forEach(element => {


            if (element.banner_platform) {
              if (screen.width < 768) {
                if (element.banner_platform == 'Mobile') {
                  this.popupimageapp = element.image;

                  this.actiontype = element.action_type;
                  this.bannerid = element.id;
                  this.bannerimage = element.image;
                  this.bannersubimage = element.subimage;
                  this.disablepopup = 0;

                }
                else {

                  // this.disablepopup = 1;
                }

              }
              if (screen.width > 768) {
                if (element.banner_platform == 'Web') {
                  this.popupimageweb = element.image;

                  this.actiontype = element.action_type;
                  this.bannerid = element.id;
                  this.bannerimage = element.image;
                  this.bannersubimage = element.subimage;
                  this.disablepopup = 0;
                }


                else {
                  // this.disablepopup = 1;
                }
              }



            }


          });
          let findsize = screen.width;
          if (findsize < 768) {
            this.popupimage = this.popupimageapp;
          }
          else {
            this.popupimage = this.popupimageweb;
          }

          // ......end.............
        }

        else if (response.response.pop_up_banner.length == 1) {
          let element = response.response.pop_up_banner[0];
          if (element.banner_platform == 'Mobile') {
            this.popupimageapp = element.image;
            if (screen.width < 768) {
              this.actiontype = element.action_type;
              this.bannerid = element.id;
              this.bannerimage = element.image;
              this.bannersubimage = element.subimage;
              this.popupimage = this.popupimageapp;
              this.disablepopup = 0;
            }

            else if (screen.width > 768) {
              this.disablepopup = 1;
            }

            else {
              this.disablepopup = 1;
            }

          }
          else if (element.banner_platform == 'Web') {
            this.popupimageweb = element.image;
            if (screen.width > 768) {
              this.actiontype = element.action_type;
              this.bannerid = element.id;
              this.bannerimage = element.image;
              this.bannersubimage = element.subimage;
              this.popupimage = this.popupimageweb;
              this.disablepopup = 0;



            }
            else if (screen.width < 768) {
              this.disablepopup = 1;

            }

            else {
              this.disablepopup = 1;
            }
          }
          else {

          }

        }

        else {
          this.disablepopup = 1;

        }




      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }
  // ..........end...............


  defaultapi(i, offset) {

    this.authservice.apipost('homePageCategoryList/', {
      "platform": 0, //0 for web and 1 for   app
      "tab": this.tab,// 0 for buy and 1 for sell
      "offset": this.realoffset
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 200) {
        let categoryiconlist = response.response.all_category_list;
        if (categoryiconlist.length > 0){
          this.defaultProductCategory = categoryiconlist[0].category_id;
          this.selectedcategoryname = categoryiconlist[0].category_name;
        }
        this.defaultapiCall(i, offset);
      }
    });
  }
  defaultapiCall(i, offset){
    this.authservice.apipost('homePage/', {
      category: 2,
      categorylev2: [this.defaultProductCategory],
      country: this.countrylocation,
      location: this.locationon == 1 && this.tab == 0 ? this.District : "",
      offset: this.realoffset,
      platform: 0,
      subcategory: [],
      tab: this.tab

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {

        // if (this.tab == 0) {
        //   this.buylist = response.response.buying_list;
        //   this.brands = response.response.brand_details;
        // }
        // else {
        //   this.selllist = response.response.selling_list;
        // }

        this.realmaxload = response.response.total_count;
        if (this.tab == 0) {
          // this.setfilterflag = 1;
          this.brands = response.response.brand_details;
          this.buylist = this.realoffset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)

          this.realoffset = this.realoffset + 1;

        }
        else {
          // this.setfilterflag = 1;
          this.brands = response.response.brand_details;
          this.selllist = this.realoffset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)

          this.realoffset = this.realoffset + 1;
        }
        this.categoryiconlisting();
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  closeall() {
    this.modalService.dismissAll();
    // added by Kunal on 02 july 2020
    this.subcategoryactive = 0;
  }





  brandapi(id, offset) {
    this.storage.store("homepagebrandtab", this.tab);
    this.storage.store("homepagebrandid", id);
    this.storage.store("homepagebanners", this.banners);
    this.storage.store("homepagebrands", this.brands)
    this.storage.store("checkhomepagebrandactive", 1);
    this.storage.store('supparentname', '');
    this.storage.store('parentcategoryname', '');
    this.storage.store('categoname', '');
    // Geolocation module added by Kunal bansal on 23 june 2021 start
    this.storage.store("locationstatus", this.locationon);
    this.storage.store("loggedstatus", this.logged);
    this.storage.store("districtname", this.District);
    // end
    this.router.navigate(['./homepage/brandlist']);
  }


  check(h) {

  }

  sortapi(i, offset) {
    if (this.isfiltered == 1) {
      this.filterapi(i, offset);
    }
    else if (this.isbranded == 1) {
      this.authservice.apipost('getBrandProductList/?sort_flag=' + i, {
        "brandid": this.selectedbrandid,
        "platform": 0, //0 for web and 1 for app
        "tab": this.tab,// 0 for buy and 1 for sell
        "offset": offset,
        // Geolocation module added by Kunal bansal on 25 june 2021 start
        "location": this.locationon == 1 && this.tab == 0 ? this.District : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          if (offset != 1) { this.realoffset = this.realoffset + 1; }
          this.realmaxload = response.response.total_count;
          if (this.tab == 0) {
            this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
          }
          else {
            this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
          }
          this.issortedtype = i;
          this.modalService.dismissAll();
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    else {

      for (let b = 0; b < this.sidecategoryfilters1.length; b++) {
        // this.selectedItems.push({ 'id': this.sidecategoryfilters1[b].id, 'name': this.sidecategoryfilters1[b].category_name })
        // this.filtersortfirst.push({ 'id': this.sidecategoryfilters1[b].id, 'name': this.sidecategoryfilters1[b].category_name })
        if (this.selectedItems.includes(this.sidecategoryfilters1[b].id)) {

        }
        else {
          this.selectedItems.push(this.sidecategoryfilters1[b].id)
          this.filtersortfirst.push(this.sidecategoryfilters1[b].id)
        }

      }
      for (let b = 0; b < this.sidecategoryfilters2.length; b++) {
        if (this.sidecategoryfilters2.includes(this.sidecategoryfilters2[b].id)) {

        }
        else {
          this.selectedItems2.push(this.sidecategoryfilters2[b].id)
          this.filtersortsecond.push(this.sidecategoryfilters2[b].id)
        }
        // this.selectedItems2.push({ 'id': this.sidecategoryfilters2[b].id, 'name': this.sidecategoryfilters2[b].category_name })
        // this.filtersortsecond.push({ 'id': this.sidecategoryfilters2[b].id, 'name': this.sidecategoryfilters2[b].category_name })

      }
      for (let b = 0; b < this.sidecategoryfilters3.length; b++) {
        // this.selectedItems3.push({ 'id': this.sidecategoryfilters3[b].id, 'name': this.sidecategoryfilters3[b].category_name })
        // this.filtersortthird.push({ 'id': this.sidecategoryfilters3[b].id, 'name': this.sidecategoryfilters3[b].category_name })
        if (this.selectedItems3.includes(this.sidecategoryfilters3[b].id)) {

        }
        else {
          this.selectedItems3.push(this.sidecategoryfilters3[b].id);
          this.filtersortthird.push(this.sidecategoryfilters3[b].id);
        }

      }

      // "category": this.selectedItems.length != 0 ? this.filtersortfirst : {},
      // "categorylev2": this.selectedItems2.length != 0 ? this.filtersortsecond : [],
      // "subcategory": this.selectedItems3.length != 0 ? { "id": this.selectedItems3[0].id, "name": this.selectedItems3[0].category_name } : [],
      this.authservice.apipost('homePage/?sort_flag=' + i, {
        "category": this.selectedItems.length != 0 ? this.filtersortfirst : {},
        "categorylev2": this.selectedItems2.length != 0 ? this.filtersortsecond : [],
        "subcategory": this.selectedItems3.length != 0 ? { "id": this.selectedItems3[0].id, "name": this.selectedItems3[0].category_name } : [],
        "platform": 0, //0 for web and 1 for app
        "tab": this.tab,// 0 for buy and 1 for sell
        "offset": offset,
        // Geolocation module added by Kunal bansal on 25 june 2021 start
        "location": this.locationon == 1 && this.tab == 0 ? this.District : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.setfilterflag = 1;
          if (offset != 1) { this.realoffset = this.realoffset + 1; }
          this.realmaxload = response.response.total_count;
          if (this.tab == 0) {
            this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
          }
          else {
            this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
          }
          this.issortedtype = i;
          this.modalService.dismissAll();
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }

  selectedfilteractivate() {
    // selected filter shown on homepage module added on 16 july 2020 by Kunal bansal start
    this.selectedFilters = [];

    this.setfilactive = 1;
    if (this.selectedItems.length != 0) {
      this.selectedFilters.push({
        "id": this.selectedItems[0].category_name
      })
    }
    if (this.selectedItems2.length != 0) {
      this.selectedFilters.push({
        "id": this.selectedItems2[0].category_name
      })
    }
    if (this.selectedItems3.length != 0) {
      this.selectedFilters.push({
        "id": this.selectedItems3[0].category_name
      })
    }

    this.filter1 = this.selectedItems;



    this.filter2 = this.selectedItems2;
    this.filter3 = this.selectedItems3;
    this.brands1 = this.selectedbrand;
    this.brands2 = this.selectedcolor;
    this.brands3 = this.selectedmemory;
    this.brands4 = this.selectedstorage;
    this.brands5 = this.selectedbattery;
    this.brands6 = this.selecteddisplay;


    // end
  }

  // module added on 17 july 2020 by Kunal bansal start

  removefilter(i, index, cname) {

    if (i == 1) {
      this.filter1.splice(index, 1)

      for (let z = 0; z < this.filtersortfirst.length; z++) {
        if (this.filtersortfirst[z].name == cname) {
          this.filtersortfirst.splice(z, 1)
        }
      }

      if (this.filtersortfirst.length >= 1) {
        this.filterapi(10, 1)
      }
      else {
        location.reload();
      }

    }
    if (i == 12) {
      this.selectedFilters.splice(index, 1)
      //  this.selectedItems.splice(index,1);
      //  this.selectedItems2.splice(0,1);
      this.selectedItems2.splice(index, 1);
      this.selectedItems3.splice(index, 1);
      this.selectedbrand = [];
      this.selectedcolor = [];
      this.selectedmemory = [];
      this.selectedstorage = [];
      this.selectedbattery = [];
      this.selecteddisplay = [];
      for (let y = 0; y < this.filtersortsecond.length; y++) {
        if (this.filtersortsecond[y].name == cname) {
          this.filtersortsecond.splice(y, 1)
        }
      }
      this.filterapi(10, 1)

    }
    if (i == 13) {
      this.selectedFilters.splice(index, 1)
      //  this.selectedItems.splice(index,1);
      //  this.selectedItems2.splice(0,1);
      this.selectedItems3.splice(index, 1);
      this.selectedbrand = [];
      this.selectedcolor = [];
      this.selectedmemory = [];
      this.selectedstorage = [];
      this.selectedbattery = [];
      this.selecteddisplay = [];

      this.filterapi(10, 1)
    }
    if (i == 2 && this.brands1.length != 0) {

      this.selectedbrand.splice(index, 1)

      this.filterapi(10, 1)
    }
    if (i == 3 && this.brands2.length != 0) {

      this.selectedcolor.splice(index, 1)

      this.filterapi(10, 1)
    }
    if (i == 4 && this.brands3.length != 0) {

      this.selectedmemory.splice(index, 1)

      this.filterapi(10, 1)
    }
    if (i == 5 && this.brands4.length != 0) {

      this.selectedstorage.splice(index, 1)

      this.filterapi(10, 1)
    }
    if (i == 6 && this.brands5.length != 0) {

      this.selectedbattery.splice(index, 1)

      this.filterapi(10, 1)
    }
    if (i == 7 && this.brands6.length != 0) {

      this.selecteddisplay.splice(index, 1)

      this.filterapi(10, 1)
    }
  }
  // end



  filterapi(issortedtype, offset) {

    let filters = [];
    // phase 6 remove product status in case want to sell added by kunal bansal on 08 june 2022 start
    let sellfilters = [];
    // ...end...

    if (this.selectedbrand != undefined && this.selectedbrand.length>0) {
      filters.push({
        "id": this.filterdata.filters[0].f_id,
        "name": this.filterdata.filters[0].filter_name,
        "selected": this.selectedbrand
      })
      // phase 6 remove product status in case want to sell added by kunal bansal on 08 june 2022 start
      if (this.filterdata.filters[0].filter_name == 'Product Status') {

      }
      else {
        sellfilters.push({
          "id": this.filterdata.filters[0].f_id,
          "name": this.filterdata.filters[0].filter_name,
          "selected": this.selectedbrand
        })
      }
      // ....end...

    }
    if (this.selectedcolor != undefined && this.selectedcolor.length>0) {
      filters.push({
        "id": this.filterdata.filters[1].f_id,
        "name": this.filterdata.filters[1].filter_name,
        "selected": this.selectedcolor
      })
      // phase 6 remove product status in case want to sell added by kunal bansal on 08 june 2022 start
      if (this.filterdata.filters[1].filter_name == 'Product Status') {

      }
      else {
        sellfilters.push({
          "id": this.filterdata.filters[1].f_id,
          "name": this.filterdata.filters[1].filter_name,
          "selected": this.selectedcolor
        })
      }
      // ....end...
    }
    if (this.selectedmemory != undefined && this.selectedmemory.length>0) {
      filters.push({
        "id": this.filterdata.filters[2].f_id,
        "name": this.filterdata.filters[2].filter_name,
        "selected": this.selectedmemory
      })
      // phase 6 remove product status in case want to sell added by kunal bansal on 08 june 2022 start
      if (this.filterdata.filters[2].filter_name == 'Product Status') {

      }
      else {
        sellfilters.push({
          "id": this.filterdata.filters[2].f_id,
          "name": this.filterdata.filters[2].filter_name,
          "selected": this.selectedmemory
        })
      }
      // ....end...
    }
    if (this.selectedstorage != undefined && this.selectedstorage.length>0) {
      filters.push({
        "id": this.filterdata.filters[3].f_id,
        "name": this.filterdata.filters[3].filter_name,
        "selected": this.selectedstorage
      })
      // phase 6 remove product status in case want to sell added by kunal bansal on 08 june 2022 start
      if (this.filterdata.filters[3].filter_name == 'Product Status') {

      }
      else {
        sellfilters.push({
          "id": this.filterdata.filters[3].f_id,
          "name": this.filterdata.filters[3].filter_name,
          "selected": this.selectedstorage
        })
      }
      // ....end...
    }
    if (this.selectedbattery != undefined && this.selectedbattery.length>0) {
      filters.push({
        "id": this.filterdata.filters[4].f_id,
        "name": this.filterdata.filters[4].filter_name,
        "selected": this.selectedbattery
      })
      // phase 6 remove product status in case want to sell added by kunal bansal on 08 june 2022 start
      if (this.filterdata.filters[4].filter_name == 'Product Status') {

      }
      else {
        sellfilters.push({
          "id": this.filterdata.filters[4].f_id,
          "name": this.filterdata.filters[4].filter_name,
          "selected": this.selectedbattery
        })
      }
      // ....end...
    }
    if (this.selecteddisplay != undefined && this.selecteddisplay.length>0) {
      filters.push({
        "id": 5,
        "name": this.filterdata.filters[5].filter_name,
        "selected": this.selecteddisplay
      })
      // phase 6 remove product status in case want to sell added by kunal bansal on 08 june 2022 start
      if (this.filterdata.filters[5].filter_name == 'Product Status') {

      }
      else {
        sellfilters.push({
          "id": this.filterdata.filters[5].f_id,
          "name": this.filterdata.filters[5].filter_name,
          "selected": this.selecteddisplay
        })
      }
      // ....end...
    }
    if (this.issortedtype != 10 || issortedtype != 10) {
      var x = this.issortedtype == 10 ? issortedtype : this.issortedtype;
      // filter sort params added and other mopification done on 24 march 2021 by Kunal bansal start
      this.authservice.apipost('filterSort/?sort_flag=' + x, {
        "category": this.selectedItems.length != 0 ? this.filtersortfirst : {},
        "categorylev2": this.selectedItems2.length != 0 ? this.filtersortsecond : [],
        "subcategory": this.selectedItems3.length != 0 ? { "id": this.selectedItems3[0].id, "name": this.selectedItems3[0].category_name } : [],
        "max_price": this.selectedhighrange,
        "min_price": this.selectedlowrange,
        "filters": this.tab == 0 ? filters : sellfilters,
        "platform": 0, //0 for web and 1 for app
        "tab": this.tab,// 0 for buy and 1 for sell
        "offset": offset,
        // Geolocation module added by Kunal bansal on 25 june 2021 start
        "location": this.locationon == 1 && this.tab == 0 ? this.District : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.sidecategoryfilters1 = [];
          this.sidecategoryfilters2 = [];
          this.sidecategoryfilters3 = [];
          this.storage.clear('sidecategoryfilter1')
          this.storage.clear('sidecategoryfilter2')
          this.storage.clear('sidecategoryfilter3')
          this.selectedfilteractivate()
          // end
          if (offset != 1) { this.realoffset = this.realoffset + 1; }
          this.realmaxload = response.response.total_count;
          if (this.tab == 0) {
            this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
          }
          else {
            this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
          }
          this.modalService.dismissAll();
          this.isfiltered = 1;
          this.selectedbrandid = '';
          this.isbranded = 0
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    // filter module added on 13 july 2020 start
    else {

      if (this.selectedItems.length == 0) {

      }
      else {

        this.authservice.apipost('filterSort/', {
          "category": this.selectedItems.length != 0 ? this.filtersortfirst : {},
          "categorylev2": this.selectedItems2.length != 0 ? this.filtersortsecond : [],
          "subcategory": this.selectedItems3.length != 0 ? { "id": this.selectedItems3[0].id, "name": this.selectedItems3[0].category_name } : [],
          "max_price": this.selectedhighrange,
          "min_price": this.selectedlowrange,
          "filters": this.tab == 0 ? filters : sellfilters,
          "platform": 0, //0 for web and 1 for app
          "tab": this.tab,// 0 for buy and 1 for sell
          "offset": offset,
          // Geolocation module added by Kunal bansal on 25 june 2021 start
          "location": this.locationon == 1 && this.tab == 0 ? this.District : "",
          // end
          // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          "country": this.countrylocation
        }).subscribe((res: any) => {
          let response = res.json();
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            Swal.fire(response.message)
          }
          else if (response.status == 200) {


            this.sidecategoryfilters1 = [];
            this.sidecategoryfilters2 = [];
            this.sidecategoryfilters3 = [];
            this.storage.clear('sidecategoryfilter1')
            this.storage.clear('sidecategoryfilter2')
            this.storage.clear('sidecategoryfilter3')
            this.selectedfilteractivate()

            if (offset != 1) { this.realoffset = this.realoffset + 1; }
            this.realmaxload = response.response.total_count;
            if (this.tab == 0) {
              this.setfilterflag = 1;
              this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)

            }
            else {
              this.setfilterflag = 1;
              this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
            }
            this.modalService.dismissAll();
            this.isfiltered = 1;
            this.selectedbrandid = '';
            this.isbranded = 0
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("Connect to the Internet")
          }
        )
      }
    }

  }

  querybutton() { //Added by Kunal bansal on 24 april 2020

    this.router.navigate(['./homepage/asknow']);
  }





  buysellchange(i, offset) {
    this.storage.store("pressedkey", i);
    this.storage.store('categorytab', i);
    this.homePageactive = this.storage.retrieve('homePageactive');

    // this.sharedService.sectionChanged$.next({section:i,category:this.storage.retrieve('catid')});

    this.realoffset = i == this.tab ? this.realoffset : 1


    if (offset == 1) {
      this.tab = i;
      if (this.issortedtype == 10 && this.isfiltered == 0 && this.isbranded == 0 && this.sidedraweractivate == 0) {



        this.defaultapi(this.tab, this.realoffset)

        // end
      }
      else if (this.issortedtype == 10 && this.isfiltered == 0 && this.isbranded == 0 && this.sidedraweractivate == 1) {
        this.sidecategory()
      }
      else if (this.issortedtype == 10 && this.isfiltered == 0 && this.isbranded == 0) {

        this.defaultapi(this.tab, this.realoffset)

        // end
      }

      else if (this.issortedtype != 10 && this.isfiltered == 1) {
        this.filterapi(this.issortedtype, 1)
      }
      else if (this.issortedtype != 10 && this.isbranded == 1) {
        this.brandapi(this.selectedbrandid, 1)
      }
      else if (this.isbranded == 1) {
        this.brandapi(this.selectedbrandid, 1)
      }
      else if (this.issortedtype != 10) {

        this.sortapi(this.issortedtype, 1)
      }
      else if (this.isfiltered == 1) {
        this.filterapi(10, 1)
      }
    }
    else {
      this.tab = i;
      if (this.issortedtype == 10 && this.isfiltered == 0 && this.isbranded == 0) {

        this.defaultapi(this.tab, this.realoffset)
      }
      else if (this.issortedtype != 10 && this.isfiltered == 1) {
        this.filterapi(this.issortedtype, offset)
      }
      else if (this.issortedtype != 10 && this.isbranded == 1) {
        this.brandapi(this.selectedbrandid, offset)
      }
      else if (this.isbranded == 1) {
        this.brandapi(this.selectedbrandid, offset)
      }
      else if (this.issortedtype != 10) {
        this.sortapi(this.issortedtype, offset)
      }
      else if (this.isfiltered == 1) {
        this.filterapi(10, offset)
      }
    }
  }


  sidecategory() {

    this.authservice.apipost('homePage/', {
      "platform": 0,
      "tab": this.tab,
      "offset": 1,
      "category": this.sidecat1.length != 0 ? this.filtersortfirst : [],
      "categorylev2": this.sidecat2.length != 0 ? this.filtersortsecond : [],
      "subcategory": this.sidecat3.length != 0 ? this.filtersortthird : [],
      // Geolocation module added by Kunal bansal on 25 june 2021 start
      "location": this.locationon == 1 && this.tab == 0 ? this.District : "",
      // end
      // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
      "country": this.countrylocation

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.realoffset = this.realoffset + 1;
        // if (this.tab == 0) {
        //   this.buylist = response.response.buying_list;
        //   this.brands = response.response.brand_details;
        // }
        // else {
        //   this.selllist = response.response.selling_list;
        // }

        this.realmaxload = response.response.total_count;
        if (this.tab == 0) {
          this.setfilterflag = 1;
          this.brands = response.response.brand_details;
          // this.buylist = this.realoffset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
          this.buylist = response.response.buying_list;

        }
        else {
          this.setfilterflag = 1;
          // this.selllist = this.realoffset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
          this.selllist = response.response.selling_list;
        }

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }
  home() {
    if (window.location.pathname == "/homepage/productListing") {
      this.storage.store('sidedraweractivate', 0)
      location.reload();
    }
    else {
      this.storage.store('sidedraweractivate', 0)
      this.router.navigate(['/homepage/productListing']);
    }
  }


  removesidefilter(index, a, id) {

    if (a == 1) {
      this.sidecategoryfilters1.splice(index, 1)

      if (this.sidecategoryfilters1.length == 0) {
        this.storage.clear('sidecategoryfilter1');
        this.storage.clear('sidecategoryfilter2');
        this.storage.clear('sidecategoryfilter3');
        this.storage.clear('sidecat1id');
        this.storage.clear('sidecat2id');
        this.storage.clear('sidecat3id')

        if (window.location.pathname == "/homepage/productListing") {
          this.storage.store('sidedraweractivate', 0);
          this.storage.store('pressedkey', 0);
          this.storage.store('categorytab', 0);
          this.storage.clear("checkhomepagebrandactive");
          location.reload();
        }
        else {
          this.storage.store('sidedraweractivate', 0);
          this.storage.store('pressedkey', 0);
          this.storage.store('categorytab', 0);
          this.router.navigate(['/homepage/productListing']);
        }

      }
      else {
        // this.sidecat1 = [];
        // this.sidecat2 = [];
        // this.sidecategoryfilters2 = [];
        // this.sidecat3 = [];
        // this.sidecategoryfilters3 = []
        let v = this.filtersortfirst.indexOf(id);
        this.filtersortfirst.splice(v, 1)
        this.sidecategoryfilters1.splice(v, 1);
        this.sidecategory();
      }


    }
    else if (a == 2) {
      // alert('hlo1')
      // this.sidecat2.splice(index, 1)
      // alert('hlo2')
      // this.sidecategoryfilters2.splice(index, 1);
      // alert('hlo3')
      // this.storage.clear('sidecategoryfilter2');
      // alert('hlo4')
      // this.storage.clear('sidecat2id');
      // alert('hlo5')
      let v = this.filtersortsecond.indexOf(id);
      this.filtersortsecond.splice(v, 1)
      this.sidecategoryfilters2.splice(v, 1);

      // let v = this.sidecat1.indexOf(id);
      // alert('hlo6')
      // this.sidecat1.splice(v, 1);

      this.sidecategory();
    }
    else {
      this.sidecat3.splice(index, 1)
      this.sidecategoryfilters3.splice(index, 1)
      this.storage.clear('sidecategoryfilter3');
      this.storage.clear('sidecat3id')
      this.sidecategory();
    }
  }

  // new module added for click at category image list render by Kunal bansal on 17 july 2020
  categoryimage(categid, parentid, catname, parname, supparid, supparname) {
    if (supparid == undefined && supparname == undefined) {
      this.storage.store('supparentid', '')
      this.storage.store('supparentname', '')

    }
    else {
      if (parname != supparname) {
        this.storage.store('supparentid', supparid)
        this.storage.store('supparentname', supparname)

      }
      else {
        this.storage.store('supparentid', '')
        this.storage.store('supparentname', '')

      }
    }
    this.catid = categid;
    this.storage.store('cattab', this.tab)
    this.storage.store('catid', categid)
    this.storage.store('parid', parentid)
    this.storage.store('categoname', catname)
    this.storage.store('parentcategoryname', parname)
    // Geolocation module added by Kunal bansal on 23 june 2021 start
    this.storage.store("locationstatus", this.locationon);
    this.storage.store("loggedstatus", this.logged);
    this.storage.store("districtname", this.District);
    // added by Kunal bansal on 15 july 2021
    sessionStorage.setItem("removesecondfilter", "0");
    // end
    this.router.navigate(['./homepage/categorylist']);

  }



  filtermoadal(filter) {
    // this.selectedbrand = [];
    this.selectedItems = [];
    this.firstlevel = [];
    this.selectedItems2 = [];
    this.secondlevel = [];
    this.selectedItems3 = [];


    // this.selectedcolor = [];
    // this.selectedmemory = [];
    // this.selectedstorage = [];
    // this.selectedbattery = [];
    // this.selecteddisplay = [];
    this.filtersortfirst = [];
    this.filtersortsecond = [];
    this.filtersortthird = [];
    this.authservice.apipost('filter_by/', { "category_id": 3 }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {

        this.filterdata = [];
        this.options.floor = response.response.min_price;
        this.selectedlowrange = 0;
        this.selectedhighrange = 0;
        this.options.ceil = response.response.max_price;

        this.modalService.open(filter, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

    // Added by Kunal on 02 july 2020 start
    this.authservice.apipost('CategoryList/', {
      flag: 'category'

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.category = response.response;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end
  }


  sortmoadal(sort) {
    this.modalService.open(sort, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
  }

  onOpen($event) {
    if ($event == true) {
      alert('open')
    }
    else {
      alert('close')
    }
  }

  onClose($event) {
    if ($event == true) {
      alert('close')
    }
    else {
      alert('close')
    }
  }


  // phase 6 added by kunal bansal on 21 april 2022 start
  adddeliaddress() {
    this.storage.store("goingthroughgeneratepickup", 4);
    // phase 6 added by Kunal bansal on 13 may 2022 start
    this.storage.store("address_type", 3)
    // ...end...
  }


  // <!-- added by KUnal bansal on 04 may 2022 start -->
  bannercountdown() {

    for (let j = 0; j < this.banners.length; j++) {
      if (this.banners[j].timer == '' && this.banners[j].timer == null) {

      }
      else {

        setInterval(() => {

          try {
            var countDownDate = new Date(this.banners[j].timer).getTime();

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;
            if (distance < 0) {
              this.banners.splice(j, 1)
            }

            // Time calculations for days, hours, minutes and seconds
            this.banners[j].days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString();

            this.banners[j].days = Number(this.banners[j].days) < 10 ? '0' + this.banners[j].days : this.banners[j].days;
            this.banners[j].hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
            this.banners[j].hours = Number(this.banners[j].hours) < 10 ? '0' + this.banners[j].hours : this.banners[j].hours;
            this.banners[j].minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString();
            this.banners[j].minutes = Number(this.banners[j].minutes) < 10 ? '0' + this.banners[j].minutes : this.banners[j].minutes;
            this.banners[j].seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();
            this.banners[j].seconds = Number(this.banners[j].seconds) < 10 ? '0' + this.banners[j].seconds : this.banners[j].seconds;

            // If the count down is finished, write some text
            if (Number(this.banners[j].days) == 0 && Number(this.banners[j].minutes) == 0 && Number(this.banners[j].minutes) == 0 && Number(this.banners[j].seconds) == 0) {
              this.banners.splice(j, 1)
              // this.isCounterRunning = false;
            }
          }
          catch (err) {
            // console.log(err.message)
          }
        }, 1000);

      }
    }

  }


  // ................end....................



  submitemitfilter(event:any){
this.selectedItems=event.categorylevel1;
this.filtersortfirst=event.categorylevel1filter;

this.selectedItems2=event.categorylevel2;
 this.filtersortsecond=event.categorylevel2filter;

 this.selectedlowrange=event.minprice;
this.selectedhighrange=event.maxprice;

 this.filterdata=event.filterdata;

 this.selectedbrand=event.selectedbrand;
 this.selectedcolor=event.selectedcolor;
 this.selectedmemory=event.selectedmemory,
  this.selectedstorage=event.selectedstorage,
  this.selectedbattery=event.selectedbattery,
  this.selecteddisplay=event.selecteddisplay

    this.filterapi(10,1)
  }

  commonfiltersort(event:any){
this.sortapi(event.value1,event.value2)
  }

  getdefaultCategoryId() {
    this.tab = this.storage.retrieve('pressedkey')
    if (this.tab == null)
      this.tab = 0;

    this.authservice.apipost('homePageCategoryList/', {
      "platform": 0, //0 for web and 1 for   app
      "tab": this.tab,// 0 for buy and 1 for sell
      "offset": this.realoffset
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 200) {
        let categoryiconlist = response.response.all_category_list;
        if (categoryiconlist.length > 0){
          this.defaultProductCategory = categoryiconlist[0].category_id;
          this.selectedcategoryname = categoryiconlist[0].category_name;
        }
        this.hithomepageapi();
      }
    })
  }

}
