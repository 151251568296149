import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataService } from '../data.service';
declare var particlesJS: any;
import $ from 'jquery'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../../assets/Newwebfiles/style.css']
})
export class HomeComponent implements OnInit {

  contents: any[] = [];
  imagesUrl: any[] = [];
  imagesUrlevent: any[] = [];
  imagesUrloffice: any[] = [];
  testimonials: any[] = [];
  youtube: SafeResourceUrl;

  constructor(private server: DataService, private router: Router, public sanitizer: DomSanitizer) {

  }



  ngOnInit() {

    this.getContents();
    this.getTeamPhotographs();
    this.getEventPhotographs();
    this.getOfficePhotographs();
    this.getTestimonials();
    this.loadScript('../../assets/js/rocket-loader.min.js');
    //particlesJS.load('home', 'assets/js/rocket-loader.min.js', function() { console.log('callback - particles.js config loaded'); });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    // <!-- s3 production image implementation module added by Kunal bansal on 26 feb 2021 -->
    this.Imagesincarousel();

  }



  // flickering issue fixed by Kunal bansal on 08 jan 2021
  private getContents() {
    this.server.getContents().then((response: any) => {
      this.contents = response.data[0];
      this.youtube = this.sanitizer.bypassSecurityTrustResourceUrl(response.data[0].sec7_video);




    });
  }

  // <!-- s3 production image implementation module added by Kunal bansal on 26 feb 2021 -->
  Imagesincarousel() {

    // Team images start
    this.imagesUrl.push(
      { 'img': "https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Team_photos/011.jpg" },
      { 'img': "https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Team_photos/022.jpg" },
      { 'img': 'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Team_photos/033.jpg' },
      { 'img': 'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Team_photos/044.jpg' },
      { 'img': 'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Team_photos/055.jpg' },
    )
    // end
    // Event images start
    // this.imagesUrlevent.push(
    //   { 'img': "https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Event_photos/evnt1.jpg" },
    //   { 'img': "https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Event_photos/evnt2.jpg" },
    //   { 'img': 'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Event_photos/evnt3.jpg' },
    // )
    // end
    // Media presence images start
    this.imagesUrloffice.push(
      { 'img': "https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Media_Presence/01.jpeg" },
      { 'img': "https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Media_Presence/02.jpg" },
      { 'img': "https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Media_Presence/03.jpeg" },
      {'img':"https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Media_Presence/04.jpg"},
      {'img':"https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Media_Presence/05.jpg"}
     )
    // end

  }
  //  end

  private getTeamPhotographs() {
    this.server.getTeamPhotographs().then((response: any) => {
      // commented on 26feb2021 by Kunal bansal
      // this.imagesUrl = response.data;
    });
  }

  private getEventPhotographs() {
    this.server.getEventPhotographs().then((response: any) => {
      this.imagesUrlevent = response.data;
    });
  }

  private getOfficePhotographs() {
    this.server.getOfficePhotographs().then((response: any) => {
      // this.imagesUrloffice = response.data;
    });
  }

  private getTestimonials() {
    this.server.getTestimonials().then((response: any) => {
      this.testimonials = response.data;
    });
  }

  public loadScript(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  openpage(a) {
    if (a == 1) {
      this.router.navigate(['AboutEOL/post-your-requirement']);
    }

    else if (a == 2) {
      this.router.navigate(['AboutEOL/technology']);
    }
    else if (a == 3) {
      this.router.navigate(['AboutEOL/career']);
    }

    else {
      this.router.navigate(['AboutEOL/AboutUs']);
    }
  }



}
