import { Component, Input, OnInit, Output ,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-ewallbill',
  templateUrl: './ewallbill.component.html',
  styleUrls: ['./ewallbill.component.css']
})
export class EwallbillComponent implements OnInit {
  // <!--phase 6 way bill ui created by Kunal on 29 april 2022 start for code optimization  -->
  // ..................passing data from child to parent  start.................
 @Output() ewayuploadedfile = new EventEmitter < any > (); 
 @Output() ewayinvoicefile= new EventEmitter < any > (); 
//  ...........................end.............................

// ..................passing data from parent to child start.................
 @Input() ewayuploadedfilename:string="";
 @Input() ewayinvoicefilename:string=""
 //  ...........................end.............................

  constructor() { }

  ngOnInit() {
  }

  // <!--phase 6 way bill ui created by Kunal on 29 april 2022 start  -->

  billupload(event){
    this.ewayuploadedfilename=event.target.files[0].name;
    this.ewayuploadedfile.emit(event.target.files[0])
    // console.log(event)
  }

  invoiceupload(event){
    this.ewayinvoicefilename=event.target.files[0].name;
    this.ewayinvoicefile.emit(event.target.files[0])
    // console.log(event)
  }

}
