import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css','../../assets/Newwebfiles/style.css']
})
export class HelpComponent implements OnInit {
  contents: any ={};
  faqs: any =[];


  constructor(private server: DataService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    // this.getfaqcontent();
    this.getFaqs();
  }

  // private getfaqcontent() {
  //   this.server.getfaqcontent().then((response: any) => {
  //     this.contents = response.data[0];
  //   });
  // }

  private getFaqs() {
    this.server.getFaqs().then((response: any) => {
      this.faqs = response.data;
      this.contents = response.faqcontent;
    });
  }

 

}
