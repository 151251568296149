// Phase 5 new api common service created by KUnal bansal on 21 oct 2021 start
import { Injectable } from '@angular/core';
import { Http, Headers } from "@angular/http";
import { SessionStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment'
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiserviceService {
  env = environment.baseurl;


  constructor(private http: Http, private storage: SessionStorageService, private spinner: NgxSpinnerService) { }


  headerreturn() {
    var headers = new Headers();
    var token = this.storage.retrieve('token');
    headers.append('Token', token);
    return headers
  }

  FetchTransactions() {
    let headers = this.headerreturn();
    this.spinner.show();
    return this.http.get(this.env + "Ledger_records/", { headers })
  }

  Searchtransactions(body, check, tok) {
    let headers = this.headerreturn();
    this.spinner.show();
    if (check == 0) {
      return this.http.post(this.env + "Ledger_records/", body, { headers })
    }
    else {
      return this.http.post(this.env + `Ledger_records/?auth_udid=${tok}`, body, { headers })
    }
  }

  // phase 5 ledger point cart payment modes added by Kunal bansal on 26 oct 2021 start
  getpaymentmodes() {
    let headers = this.headerreturn();
    this.spinner.show();
    return this.http.get(this.env + "RazorPyamentMethod/", { headers })
  }
  // ...end...


  // phsae 5 new cr my transactions scenario added by Kunal bansal on 27 oct 2021 start
  Downloadcsvpdftransactions(type,daterange,tokentype,agenttoken) {
    var token = this.storage.retrieve('token');
    let openurl = `${environment.baseurl}ledger_report/?date_range=${daterange}&rtype=${type}&auth_udid=${tokentype=='usertoken'?token:agenttoken}`;
    window.open(openurl);
    Swal.fire("your " + type + " file downloaded");

  }
  // ...end...

  // phase 5 new cr add money to other user behalf of agent scenario added by Kunal bansal on 01 nov 2021 start
  fetchallbuyerlist() {
    let headers = this.headerreturn();
    this.spinner.show();
    return this.http.get(this.env + "refered_buyers/", { headers })
  }

  // Phase 5 new api specific user detail on behalf of agent created by KUnal bansal on 11 nov 2021 start
  specificusertransaction(tok) {
    let headers = this.headerreturn();
    this.spinner.show();
    return this.http.get(this.env + `Ledger_records/?auth_udid=${tok}`, { headers })
  }

  // phase 5 add money on behalf of agent added by Kunal bansal on 12 nov 2021
  addmoneytowallet(body) {
    let headers = this.headerreturn();
    this.spinner.show();

    return this.http.post(this.env + `add_money/`, body, { headers })

  }

    // phase 5 add money on behalf of get detail by order number added by Kunal bansal on 18 nov 2021
  getorderdetailbyordernumber(body){
    let headers = this.headerreturn();
    this.spinner.show();
    return this.http.post(this.env + `order_transaction_info/`, body, { headers })
  }


}
