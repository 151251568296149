import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.css','../../assets/Newwebfiles/style.css']
})
export class WhatWeDoComponent implements OnInit {

  contents:any[]=[];
  imagesUrl:any[]=[];

  constructor(private server: DataService, private router: Router) {
    //this.loadScripts();
   }

  ngOnInit() {
    // <!-- s3 production image implementation by Kunal bansal on 26 feb 2021 -->
    this.imagesUrl.push(
      {'img':"https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Workflow/How-Buying-Process-Works.jpg"},
      {'img':"https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Workflow/Order-Processing-By-Seller.jpg"},
      {'img':"https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Workflow/Registration-&-Onboarding-Process.jpg"},
      {'img':"https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Workflow/4-Inventory-Management-By-Seller.jpg"},
      )
      // end
    if(this.router.url == '/workflow'){
      document.querySelector('#my-flow').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }else{
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
      });
    }

    this.getWhatwedoContents();
    this.getWordkflow();

  }

  private getWhatwedoContents() {
    this.server.getWhatwedoContents().then((response: any) => {
      this.contents = response.data[0];
    });
  }

  private getWordkflow() {
    this.server.getWordkflow().then((response: any) => {
      console.log(response.data);
      // this.imagesUrl = response.data;
    });
  }

  // loadScripts() {
  //   const dynamicScripts = [
  //    '../../assets/js/commondca2.js',
  //   ];
  //   for (let i = 0; i < dynamicScripts.length; i++) {
  //     const node = document.createElement('script');
  //     node.src = dynamicScripts[i];
  //     node.type = 'text/javascript';
  //     node.async = false;
  //     node.charset = 'utf-8';
  //     document.getElementsByTagName('head')[0].appendChild(node);
  //   }
  // }

  // <!-- phase 6 new website correction navigate to section module added by kunal bansal on 27 may 2022 start -->

  clickablesection(value:number){
    if(value==1){
      document.querySelector('#section1').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if(value==2){
      document.querySelector('#section2').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if(value==3){
      document.querySelector('#section3').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if(value==4){
      document.querySelector('#section4').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else{

    }

  }


}
