import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiserviceService } from 'src/app/Services/apiservice.service';
import Swal from 'sweetalert2';
import { Currentdate } from '../../Services/getcurrentdate';

@Component({
  selector: 'app-my-buyers',
  templateUrl: './my-buyers.component.html',
  styleUrls: ['./my-buyers.component.css']
})
export class MyBuyersComponent implements OnInit {

  Buyerlisting: any = [{ "store_name": "charcoal", "email": "abc@gmail.com", "balance_amount": 200, "phone": 99865765210 }];
  selectedbuyer: any = [
  ];
  buyerfilterarray: any = [{ "store_name": "charcoal", "email": "abc@gmail.com", "balance_amount": 200, "phone": 99865765210 }];
  buyersearch: string;
  loadwallet = true;
  paymentmode: number = 0;

  storename: string;
  usertoken: string;



  // add money popup params
  useremail: string;
  userphone: string;
  amount: string;
  remarks: string="";
  paymentdate: string;
  paymenttime: any=[];
  ordernumber: string="";
  transactiondetail: string;
  walletbalance: string;

  // addmoney popup balance section params
  Balanceui: any;

  searchconcatlisting:any=[];
  currentdate:string;

  constructor(private current:Currentdate,private router: Router, private modalService: NgbModal, private trans: ApiserviceService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.fetchbuyerlist();
    this.getcurrentdate();
  }

  // getcurrentdate service function by Kunal bansal on 22 nov 2021 start
getcurrentdate(){
this.current.Getcurrentdate().map(res=>{
  this.currentdate=res;
})


}

  fetchbuyerlist() {
    this.trans.fetchallbuyerlist().subscribe(res => {
      let response = res.json();
      this.spinner.hide();
      if (response.status == 200) {
        console.log(response);
        this.Buyerlisting = response.response;
  for(let concat=0;concat<response.response.length;concat++){
    this.searchconcatlisting.push(response.response[concat].store_name+" "+response.response[concat].phone+" "+response.response[concat].email);
  }
        this.buyerfilterarray = response.response;
      }
      else if (response.status == 401) {
        Swal.fire(response.message)
      }
      else {
        Swal.fire(response.message);
      }

    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  closeall() {
    this.modalService.dismissAll();
  }

  buyersea() {
    this.buyerfilterarray = [];
    for (let b = 0; b < this.Buyerlisting.length; b++) {
      if (this.searchconcatlisting[b].toLowerCase().includes(this.buyersearch.toLowerCase())) {
        this.buyerfilterarray.push(this.Buyerlisting[b])
        // console.log(this.buyerfilterarray)
      }
    }
    // console.log(this.buyerfilterarray)
  }
  // end

  // Phase 5 new api specific user detail on behalf of agent created by KUnal bansal on 11 nov 2021 start
  viewtransactionhistory(token, storename) {

    sessionStorage.setItem("tokentype", "agenttoken");
    sessionStorage.setItem("agenttoken", token);
    sessionStorage.setItem("agentstorename", storename);
    this.router.navigate(["/homepage/transactions"]);
    // let body={
    //   "auth_udid":token
    // }
    // this.trans.specificusertransaction(token).subscribe(res => {
    //   let response = res.json();
    //   this.spinner.hide();
    //   if (response.status == 200) {
    //     console.log(response);

    //   }
    //   else if (response.status == 401) {
    //     Swal.fire(response.message)
    //   }
    //   else {
    //     Swal.fire(response.message);
    //   }

    // }
    //   , err => {
    //     this.spinner.hide();
    //     Swal.fire("Connect to the Internet")
    //   }
    // )
  }



  // Phase 5 new api specific user detail on behalf of agent created by KUnal bansal on 11 nov 2021 start

  viewbuyer(dialog, array) {
    this.modalService.open(dialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
    this.selectedbuyer = [];
    console.log(array)
    // let array = {
    //   store_name: "Charcoal",
    //   address: "58,502, Iskon Arcade c.G. Road Ahmedabad",
    //   phone: null,
    //   email: "Abc@gmail,.com",
    //   gst_number: null
    // }
    if (array.store_name != null) {
      this.selectedbuyer.push({ "title": array.store_name, imgurl: "../../../assets/images/my-buyers/contact.png" });
    }
    if (array.address != null) {
      this.selectedbuyer.push({ "title": array.address.receiving_name+", "+array.address.receiving_phone+", "+array.address.pincode+", "
      +array.address.address_line_1+", "+array.address.address_line_2+", "+array.address.state+", "+array.address.city, "imgurl": "../../../assets/images/my-buyers/location.png" });
    }
    if (array.phone != null) {
      this.selectedbuyer.push({ "title": array.phone, "imgurl": "../../../assets/images/my-buyers/phone.png" });
    }
    if (array.email != null) {
      this.selectedbuyer.push({ "title": array.email, "imgurl": "../../../assets/images/my-buyers/gmail.png" });
    }
    if (array.gst_number != null) {
      this.selectedbuyer.push({ "title": array.gst_number, "imgurl": "../../../assets/images/my-buyers/gst.png" })
    }
  }

  addmoney(dialog, array) {
    this.modalService.open(dialog, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
    this.storename = array.store_name;
    this.usertoken = array.user_token;
    this.useremail = array.email;
    this.userphone = array.phone;
    this.walletbalance = array.balance_amount;
    
    this.ordernumber="";
    this.Balanceui=undefined;
  }


  resetpopup() {
    this.useremail = "";
    this.userphone ="";
    this.amount = "";
    this.remarks = "";
    this.paymentdate = "";
    this.paymenttime = [];
    this.ordernumber = "";
    this.transactiondetail = "";
    this.walletbalance = "";
    this.Balanceui=[];
  }

  // phase 5 add money on behalf of agent added by Kunal bansal on 12 nov 2021 
  submitmoney() {
 
    if(this.loadwallet==false && this.ordernumber==""){
      Swal.fire("Order number is mandatory")
    }
    else if(this.amount=="" || this.paymentdate=="" || this.paymenttime.length==0 ){
      Swal.fire("Please select all mandatory fields")
    }
    else{

    console.log(this.paymenttime)
    let checkpaymmentmode;
    var customtimestamp = this.paymentdate + " " + this.paymenttime;


    if (this.paymentmode == 0) {
      checkpaymmentmode = "Cash"
    }
    else if (this.paymentmode == 1) {
      checkpaymmentmode = "Net Banking"
    }
    else {
      checkpaymmentmode = "Others"
    }

    let body = {
      "money_type": this.loadwallet == true ? "pay_againts_wallet" : "pay_againts_order",
      "order_number": this.loadwallet == true ? "" : this.ordernumber,
      "amount": Number(this.amount),
      "remarks": this.remarks,
      "payment_mode": checkpaymmentmode,
      "transaction_details": this.paymentmode == 0 ? "" : this.transactiondetail,
      "date": customtimestamp,
      "email": this.useremail,
      "user_type": "buyer",
      "phone": Number(this.userphone)
    }

    this.trans.addmoneytowallet(body).subscribe(res => {
      let response = res.json();
      this.spinner.hide();
      if (response.status == 200) {
        this.modalService.dismissAll();
        Swal.fire(response.message);
        this.resetpopup();
        this.fetchbuyerlist();
      }
      else if (response.status == 401) {
        Swal.fire(response.message)
      }
      else {
        Swal.fire(response.message);
      }

    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    }
  }

  // ...end...


  // phase 5 add money on behalf of get detail by order number added by Kunal bansal on 18 nov 2021 
  getorderdetailbynumber() {
    if(this.ordernumber=="" || this.ordernumber==undefined){

    }
    else{
    let body = {
      "order_number": Number(this.ordernumber)
    }
    this.trans.getorderdetailbyordernumber(body).subscribe(res => {
      let response = res.json();
      this.spinner.hide();
      if (response.status == 200) {

        this.Balanceui = response.response;
        console.log(this.Balanceui)
      }
      else if (response.status == 401) {
        Swal.fire(response.message)
      }
      else {
        Swal.fire(response.message);
      }

    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    }
  }

  radiochange(no) {
    if (no == 0) {
      this.loadwallet = true;
    }
    else {
      this.loadwallet = false;
    }
  }

  paymentswitch(mode) {
    this.paymentmode = mode;
  }

}