import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Globals } from '../globals';
import { SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-my-coupon',
  templateUrl: '../commondesign/commondesign.html',
  styleUrls: ['./my-coupon-products.component.css']
})
export class MyCouponProductsComponent implements OnInit {


  buylist: any
  parentlabel: any
  tab:number=0;
  buysell=0
  activepage:string = 'searchpage'

  constructor(private authService:AuthService,
    private storage: SessionStorageService,
    private router:Router) { }

  ngOnInit() {
    this.getProductsWithCoupons();
  }




  getProductsWithCoupons() :void{
    this.authService.apipost('user/coupons/products/',{offset:0}).subscribe({
      next:(response:any)=>{
        if(response.status != 200){
          Swal.fire(response.message)
          return;
        }
        const data = response.json();

        const groupedProducts = Object.values((Object as any).groupBy(data.data[0],({category_id})=>category_id))

         const filteredProducts = groupedProducts.map((product:any[])=>{
          return ({
            category:null,
            categorylev2:product[0].sub_category_name,
            new_prod_list:product.map(p => ({...p,coupon_available : true})),
            subcategory: product[0].category_name
          })

        });

        console.log(filteredProducts);

        this.parentlabel='Coupons';
        this.buylist = filteredProducts;


      },
      error:(error)=>{
        Swal.fire('Something went wrong')
      },
    });

  }

  pdetail(id, cateid) {
    this.storage.store("pdid", id);
    this.storage.store("ptype", 0);
    this.router.navigate(['/homepage/productdetail'], { queryParams: { pid: id, catid: cateid, ptype: 0 } });
  }

  Removelabel (id,child){
    this.router.navigate(['homepage/productlisting'])
  }
  buysellchange(tab){
    if(tab == 1)
      this.router.navigate(['homepage/productlisting'])
  }
}
