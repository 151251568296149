import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import {FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-buy-request',
  templateUrl: './buy-request.component.html',
  styleUrls: ['./buy-request.component.css']
})
  
export class BuyRequestComponent implements OnInit {
 
// phase 6 want more quantity dynamic selection produts module added by kunal bansal on 19 april 2022 start -->
wantmorequaform: FormGroup;
   brands: any = [];
   models: any = [];
   lastlevelcategory: any[];
   pos1: string = "";
   pos2: string = "";
   pos3: string = "";
   pos1name: string;
   pos2name: string;
   pos3name: string;
   prop1select: any[];
   prop2select: any[];
   prop3select: any[];
   categoryid: string = "";
   productid: string = "";
  
   // ....end....

 
  
  constructor(private formBuilder: FormBuilder,private modalService: NgbModal,private storage: SessionStorageService, private router: Router, private authservice: AuthService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.childcategory();
    // phase 6 want more quantity dynamic selection produts module added by kunal bansal on 19 april 2022 start -->
    this.wantmorequaform = this.formBuilder.group({

      brand2: [''],
      category3: [''],
      modelname2: [''],
      description2: [''],
      pos1: [''],
      pos2: [''],
      pos3: [''],
      quantity: [''],
      price: [''],
      variantid: [''],
    });

  }
 

  childcategory() {
    this.authservice.apipost('CategoryList/', {
      flag: 'subcategoryall'

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.lastlevelcategory = response.response;

        this.wantmorequaform.patchValue({
          category3:this.storage.retrieve("productdetailscatid"),
        })
        this.categoryid=this.storage.retrieve("productdetailscatid");
    // ...............on sucessfull response of categorylisting hit api of brand list start.............
        this.authservice.apipost('CategoryBrandList/', {
          category:this.wantmorequaform.value.category3
  
        }).subscribe((res: any) => {
          let response = res.json();
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            this.brands = [];
            this.models = [];
            this.wantmorequaform.patchValue({
              modelname2: "",
              brand2: "",
              pos1: "",
              pos2: "",
              pos3: "",
            });
            this.categoryid = "";
            this.productid = "";
          }
  
          else if (response.status == 200) {
            this.brands = response.response;
            this.models = [];
            this.productid = "";
            this.wantmorequaform.patchValue({
              modelname2: "",
              brand2: this.storage.retrieve("brandid"),
              pos1: "",
              pos2: "",
              pos3: "",
              variantid: "",
            });
             // ...............on sucessfull response of brandlisting hit api of product list start.............
             this.authservice.apipost('static_Filter_Data/?rtype=product', {
              "category": [],
              "brand": [this.wantmorequaform.value.brand2]
            }).subscribe((res: any) => {
              let response = res.json();
              if (response.status == 400 || response.status == 500 || response.status == 100) {
                this.models = [];
                this.wantmorequaform.patchValue({
                  modelname2:"" ,
                  pos1: "",
                  pos2: "",
                  pos3: "",
                  variantid: "",
                });
                this.productid = "";
                // Swal.fire(response.message)
              }
              else if (response.status == 200) {
                // this.selectedItems2=[];
                this.models = response.response;
                this.wantmorequaform.patchValue({
                  modelname2: this.storage.retrieve("productid"),
                  pos1: "",
                  pos2: "",
                  pos3: "",
                  variantid: "",
                });
                this.productid = this.storage.retrieve("productid");
                   // ...............on sucessfull response of model lsting hit api of variant list start.............
                this.authservice.apipost('buyRequest/?request_flag=2', {
                  "product_id": this.wantmorequaform.value.modelname2,
                }).subscribe((res: any) => {
                  let response = res.json();
                  if (response.status == 400 || response.status == 500 || response.status == 100) {
                    Swal.fire(response.trace);
                    this.wantmorequaform.patchValue({
                      modelname2: "",
                      pos1: "",
                      pos2: "",
                      pos3: "",
                      variantid: "",
                    });
                    this.productid = "";
                  }
                  else if (response.status == 200) {
          console.log(res)
          
                    this.pos1name = response.response.variant_label;
                    this.prop1select = response.response.variant_list;
                    // this.pos1name = response.response.option_list2[0].db_key;
                    // this.pos2name = response.response.option_list2[1].db_key;
                    // this.pos3name = response.response.option_list2[2].db_key;
                    // this.prop1select = response.response.option_list2[0].p_value;
                    // this.prop2select = response.response.option_list2[1].p_value;
                    // this.prop3select = response.response.option_list2[2].p_value;
                    this.wantmorequaform.patchValue({
                      pos1: "",
                      pos2: "",
                      pos3: "",
                      variantid: "",
                    });
          
                  }
                  else { Swal.fire("Server Down") }
                }
                  , err => {
                    this.spinner.hide();
                    Swal.fire("Connect to the Internet")
                  }
                )
              }
              else { Swal.fire("Server Down") }
            }
              , err => {
                this.spinner.hide();
                Swal.fire("ERROR : " + err)
              }
            )

            //  ...end...
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("Connect to the Internet")
          }
        )
        // ............end...........

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  missingproduct(event) {
    this.categoryid = event.target.value;

    this.wantmorequaform.value.brand2 = '';
    if (event.target.value == "") {
      this.brands = [];
      this.models = [];
      this.wantmorequaform.patchValue({
        modelname2: "",
        brand2: "",
        pos1: "",
        pos2: "",
        pos3: "",
        variantid: "",
      });
      this.categoryid = "";
      this.productid = "";

    }
    else {
      this.authservice.apipost('CategoryBrandList/', {
        category: event.target.value

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          this.brands = [];
          this.models = [];
          this.wantmorequaform.patchValue({
            modelname2: "",
            brand2: "",
            pos1: "",
            pos2: "",
            pos3: "",
          });
          this.categoryid = "";
          this.productid = "";
        }

        else if (response.status == 200) {
          this.brands = response.response;
          this.models = [];
          this.productid = "";
          this.wantmorequaform.patchValue({
            modelname2: "",
            brand2: "",
            pos1: "",
            pos2: "",
            pos3: "",
            variantid: "",
          });
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }

  }

  Bybrand(item: any) {
    console.log(item.target.value)

    if (item.target.value == "") {
      this.models = [];
      this.productid = "";
      this.wantmorequaform.patchValue({
        modelname2: "",
        pos1: "",
        pos2: "",
        pos3: "",
        variantid: "",
      });
    }
    else {
      this.authservice.apipost('static_Filter_Data/?rtype=product', {
        "category": [],
        "brand": [item.target.value]
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          this.models = [];
          this.wantmorequaform.patchValue({
            modelname2: "",
            pos1: "",
            pos2: "",
            pos3: "",
            variantid: "",
          });
          this.productid = "";
          // Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // this.selectedItems2=[];
          this.models = response.response;
          this.wantmorequaform.patchValue({
            modelname2: "",
            pos1: "",
            pos2: "",
            pos3: "",
            variantid: "",
          });
          this.productid = "";
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("ERROR : " + err)
        }
      )
    }

  }

  getproductid(event) {
    this.productid = event.target.value;
    if (event.target.value == "") {
      this.wantmorequaform.patchValue({
        modelname2: "",
        pos1: "",
        pos2: "",
        pos3: "",
        variantid: "",
      });
      this.productid = "";
    }
    else {
      this.authservice.apipost('buyRequest/?request_flag=2', {
        "product_id": this.productid,
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.trace);
          this.wantmorequaform.patchValue({
            modelname2: "",
            pos1: "",
            pos2: "",
            pos3: "",
            variantid: "",
          });
          this.productid = "";
        }
        else if (response.status == 200) {
console.log(res)

          this.pos1name = response.response.variant_label;
          this.prop1select = response.response.variant_list;
          // this.pos1name = response.response.option_list2[0].db_key;
          // this.pos2name = response.response.option_list2[1].db_key;
          // this.pos3name = response.response.option_list2[2].db_key;
          // this.prop1select = response.response.option_list2[0].p_value;
          // this.prop2select = response.response.option_list2[1].p_value;
          // this.prop3select = response.response.option_list2[2].p_value;
          this.wantmorequaform.patchValue({
            pos1: "",
            pos2: "",
            pos3: "",
            variantid: "",
          });

        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }

  }

  // phase 6 floating btn want more quant fucntioanlity added by kunal bansal on 13 april 2022 start
  getselectedvariant(event){
   this.prop1select.forEach(element => {
     if(element.variant_id==event.target.value){
      this.wantmorequaform.patchValue({
        pos1: element.property_1,
        pos2: element.property_2,
        pos3: element.property_3,
      });
     }
   });
  }
  // ...end...

  submitmorequanrqst(valuerender) {
    let value = valuerender.value;
    console.log(value)

    if (this.wantmorequaform.value.pos1 == undefined || this.wantmorequaform.value.pos2 == undefined || this.wantmorequaform.value.pos3 == undefined || value.description2 == undefined) {
      Swal.fire('Please fill in the mandatory fields');
    }
    else {
      this.authservice.apipost('buyRequest/?request_flag=1', {
        "product_id": Number(value.variantid),
        "category_id": Number(this.categoryid),
        "position1": value.pos1,
        "position2": value.pos2,
        "position3": value.pos3,
        "quantity": Number(value.quantity),
        "comment": value.description2,
        price:Number(value.price)
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);
        }
        else if (response.status == 200) {
          Swal.fire(response.message);

          // this.lastlevelcategory = [];
          // this.brands = [];
          // this.models = [];
          // this.wantmorequaform.patchValue({
          //   brand2: '',
          //   category3: '',
          //   modelname2: '',
          //   description2: '',
          //   pos1: '',
          //   pos2: '',
          //   pos3: '',
          //   quantity: '',
          //   variantid: '',
          //   price: '',
          // });
          // this.productid='';
          // this.categoryid='';
          this.router.navigate(['/homepage/productListing'])

          this.modalService.dismissAll();
        }
        else { Swal.fire("Server Down"); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )
    }

  }





}