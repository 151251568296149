import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-razorpayreplica',
  templateUrl: './razorpayreplica.component.html',
  styleUrls: ['./razorpayreplica.component.css']
})
export class RazorpayreplicaComponent implements OnInit {
  Eolspecialcharge:number;
  selectedpaymodeforwallet:string;
  paymentmethod = {
    netbanking: false,
    card: false,
    wallet: false,
    upi: false,
    emi: false
  };
  @Input() razorpayreplicamodes:any=[];
  @Input() Dynamicpaymentmodeprices={
    NetBanking:0,
    Card:0,
    Wallet:0,
    UPI:0,
    EMI:0
  };

  @Output() Razorpayreplicacall=new EventEmitter<any> ();

  
   
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  closeaddress(){
    this.modalService.dismissAll();
  }

   // phase 5 new cr razor pay window replica added by Kunal bansal on 02 nov 2021 start

   selectrazormode(razo) {

    if (razo == "card") {
      // Dynamic payment mode price added by Kunal bansal on 06 dec 2021
    this.Eolspecialcharge=this.Dynamicpaymentmodeprices.Card;
    this.selectedpaymodeforwallet="Credit/Debit card";
      this.paymentmethod = { netbanking: false, card: true, wallet: false, upi: false, emi: false }
    }
    else if (razo == "netbanking") {
      // Dynamic payment mode price added by Kunal bansal on 06 dec 2021
      this.Eolspecialcharge=this.Dynamicpaymentmodeprices.NetBanking;
      this.selectedpaymodeforwallet="Netbanking";
      this.paymentmethod = { netbanking: true, card: false, wallet: false, upi: false, emi: false }
    }
    else if (razo == "wallet") {
      // Dynamic payment mode price added by Kunal bansal on 06 dec 2021
      this.Eolspecialcharge=this.Dynamicpaymentmodeprices.Wallet;
      this.selectedpaymodeforwallet="Wallet";
      this.paymentmethod = { netbanking: false, card: false, wallet: true, upi: false, emi: false }
    }
    else if (razo == "upi") {
      // Dynamic payment mode price added by Kunal bansal on 06 dec 2021
      this.Eolspecialcharge=this.Dynamicpaymentmodeprices.UPI;
      this.selectedpaymodeforwallet="UPI";
      this.paymentmethod = { netbanking: false, card: false, wallet: false, upi: true, emi: false }
    }
    else if (razo == "emi") {
      // Dynamic payment mode price added by Kunal bansal on 06 dec 2021
      this.Eolspecialcharge=this.Dynamicpaymentmodeprices.EMI;
      this.selectedpaymodeforwallet="EMI";
      this.paymentmethod = { netbanking: false, card: false, wallet: false, upi: false, emi: true }
    }
    else {

    }
    this.Razorpayreplicacall.emit({'Eolspecialcharge':this.Eolspecialcharge,'selectedpaymodeforwallet':this.selectedpaymodeforwallet,'paymentmethod':this.paymentmethod})
    this.modalService.dismissAll();
    console.log(this.paymentmethod)
   


  }

}
