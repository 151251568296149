export const environment = {
  production: false,
  // baseurl: 'https://admin.eolstocks.com/app_api/',
  baseurl: 'https://eol6.softprodigyphp.in/app_api/',
  invshare: "https://www.eolstocks.com/homepage/productdetail?pid=",
  razor_key: 'rzp_live_WtJGWUE64Rx0Yh',
  serverUrl: 'https://admin.eolstocks.com/app_api/homecontent/?request_type=',
  staticfileurl:'https://admin.eolstocks.com/app_api/GetStaticFiles/?request_type='
};


// export const environment = {
//   production: false,
//   // https://eoladmin.softprodigyphp.in/app_api/
//   baseurl : ' http://eoladmin2.softprodigyphp.in/app_api/',
//   razor_key : 'rzp_test_Xl0iS8BgLClxsP',
//   serverUrl:'http://eollaravel-env.eba-kvexd4hc.ap-south-1.elasticbeanstalk.com/api'
// };
