import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css','../../assets/Newwebfiles/style.css']
})
export class TeamComponent implements OnInit {
  teams:any[]=[];
  section:any;

  ourTeamContent:any;

  constructor(private server: DataService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    this.getleadershipcontent();
    this.getOurTeamContent();
  }

  private getOurTeamContent(){
    this.server.getourteamcontent().then((response:any)=>{
      this.ourTeamContent = response.data.our_teams;
    })
  }

  private getleadershipcontent() {
    this.server.getleadershipcontent().then((response: any) => {
      this.teams = response.data.teams;
      this.section = response.data.section[1];
    });
  }

}
