import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-kyc-upload',
  templateUrl: './kyc-upload.component.html',
  styleUrls: ['./kyc-upload.component.css']
})

export class KycUploadComponent implements OnInit {
  kycForm: FormGroup;
  pancard: File = null;
  cheque: File = null;
  additional: File = null;
  country:string="UAE";
  vatcertificate:File=null;
  
  checkadditionaldocumentstatus:string;
  termsconditions:boolean=false;

  constructor(private storage: SessionStorageService,private authservice: AuthService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private router: Router) { }
  // account number min length changed from 12 to 11 by Kunal bansal on 28/ aug 2020
  ngOnInit() {
    this.country = sessionStorage.getItem('phase5country');
    this.checkadditionaldocumentstatus=sessionStorage.getItem("additionaldocumentuploaded",);
    // <!-- UAE kyc fields added by Kunal bansal on 07 dec 2021 -->
    if(this.country=='India'){

    this.kycForm = this.formBuilder.group({
   
      gstnumber: ['', Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15)])],
      firmname: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(50)])],
      pannumber: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      pancard: [''],
      cheque: [''],
      // /      <!-- address field removed by Kunal bansal on 26 may 2021 start -->
      // address: ['', Validators.compose([Validators.required,Validators.minLength(9),Validators.maxLength(140)])],
      // end
      bankname: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(50)])],
      acnumber: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(16)])],
      ifsc: ['', Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)])],
      additional: [''],

      gst_doc:[''],
      iban:[''],
      swiftcode:[''],
      trnno:['']
    });
  }
  else{
    this.kycForm = this.formBuilder.group({
   
      gstnumber: ['', Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15)])],
      firmname: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(50)])],
      pannumber: [''],
      pancard: [''],
      cheque: [''],
      // /      <!-- address field removed by Kunal bansal on 26 may 2021 start -->
      // address: ['', Validators.compose([Validators.required,Validators.minLength(9),Validators.maxLength(140)])],
      // end
      bankname: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(50)])],
      acnumber: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(16)])],
      ifsc: [''],
      additional: [''],

      gst_doc:[''],
      iban:['', Validators.compose([Validators.required, Validators.minLength(23), Validators.maxLength(23)])],
      swiftcode:['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(11)])],
      trnno:['', Validators.compose([Validators.required, Validators.minLength(15), Validators.maxLength(15)])]
    });
  }
    $(document).ready(function () {
      $('input[type=text]').focusout(function () {
        var text = $(this).val();
        text = $.trim(text);
        $(this).val(text);
      });
    })
    this.validateaddress();
  }


  // regex(){
  //   // let regextesting=^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$ 
  //   let swiftcoderegex=/^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}[0-9]{3}$/
  //   let ibanregex=/^[a-zA-Z]{2}[0-9]{17}$/
   
  //   let experiment=/^([a-zA-Z]+[a-zA-Z0-9.-]*)@([a-zA-Z]+).(com|co|in|net|org)$/
  //   let testemail=/^([a-zA-Z0-9]+)@[a-z].(com){2,3}$/
  //   // let experiment2=/^([a-zA-Z]+[a-zA-Z0-9.-]*)@([a-zA-Z]+).co$/
  //   if(experiment.test(this.kycForm.value.bankname.trim())==true){
  //     Swal.fire("succed")
  //   }
  //   else{
  //     Swal.fire("fail")
  //   }
  // }

  ngOnDestroy(){
    Swal.close();
  }

  validateaddress(){
           // phase 6 adress checking on coming on kyc upload screen rathern then after submitting by kunal bansal on 06 july 2022 start
           let shippingaddressadded= Number(sessionStorage.getItem('kycshippingaddresslength'));
           let billingaddressadded= Number(sessionStorage.getItem('kycbillingaddresslength'));
           // ...end...
         // phase 6 add mutliple address check scenario added by kunal bansal on 27 may 2022 start
             if (shippingaddressadded == 0 && billingaddressadded>0) {
               Swal.fire({
                //  title: 'Warning',
                 text: "Currently there is no shipping address added in user profile. you have to add address before proceeding",
       allowOutsideClick:false
                //  showCancelButton: true,
       
                //  cancelButtonText: 'No',
                //  confirmButtonText: 'YES',
                //  reverseButtons: true
               }).then((result) => {
                 if (result.value) {
                   this.storage.store("goingthroughgeneratepickup", 5);
                 
            this.storage.store("address_type",2)
            // ...end...
                   this.router.navigate(['/homepage/addAddress']);
                 }
               })
             }
             else if (shippingaddressadded>0  && billingaddressadded== 0) {
               Swal.fire({
                 title: 'Warning',
                 text: "Currently there is no billing address added in user profile. you have to add address before proceeding",
                 allowOutsideClick:false
                //  showCancelButton: true,
       
                //  cancelButtonText: 'No',
                //  confirmButtonText: 'YES',
                //  reverseButtons: true
               }).then((result) => {
                 if (result.value) {
                   this.storage.store("goingthroughgeneratepickup", 5);
                 
            this.storage.store("address_type",1)
            // ...end...
                   this.router.navigate(['/homepage/addAddress']);
                 }
               })
             }
             else if (shippingaddressadded == 0 && billingaddressadded==0) {
               Swal.fire({
                 title: 'Warning',
                 text: "Currently there is no billing and shipping address added in user profile. you have to add address before proceeding",
                 allowOutsideClick:false 
                //  showCancelButton: true,
       
                //  cancelButtonText: 'No',
                //  confirmButtonText: 'YES',
                //  reverseButtons: true
               }).then((result) => {
                 if (result.value) {
                   this.storage.store("goingthroughgeneratepickup", 5);
                 
            this.storage.store("address_type",2)
            // ...end...
                   this.router.navigate(['/homepage/addAddress']);
                 }
               })
              }
               else{
              }
  }

  termconditionchange(event){

    this.termsconditions=event.target.checked;
  }

  kyc({ value, valid }) {
 
    if (valid == false) {
      Swal.fire("Please fill all the Mandatory fields")
    }
    else if(this.termsconditions==false){
      Swal.fire("Please accept KYC terms and conditions")
    }
     else {
      if (this.kycForm.value.pancard == "" && this.country=='India') {
        Swal.fire("Please upload an image of your Pancard")
      }
      else if (this.kycForm.value.cheque == "" && this.country=='India') {
        Swal.fire("Please upload an image of a cancelled cheque")
      }
      // <!-- change gst certificate optional field to mandatory on 09 aug 2021 by Kunal start -->
      else if (this.kycForm.value.additional == "" && this.country=='India') {
        Swal.fire("Please upload an image of a gst certificate")
      }
      // end

      else {
           // phase 6 add address alert scenario by kunal bansal on 07 april 2022 start
            // phase 6 navigate to add address thorugh kyc upload added by kunal bansal on 24 may 2022 start
    let shippingaddressadded= Number(sessionStorage.getItem('kycshippingaddresslength'));
    let billingaddressadded= Number(sessionStorage.getItem('kycbillingaddresslength'));
    // ...end...
  // phase 6 add mutliple address check scenario added by kunal bansal on 27 may 2022 start
      if (shippingaddressadded == 0 && billingaddressadded>0) {
        Swal.fire({
          title: 'Warning',
          text: "Currently there is no shipping address added in user profile. Do you want to add address?",

          showCancelButton: true,

          cancelButtonText: 'No',
          confirmButtonText: 'YES',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.storage.store("goingthroughgeneratepickup", 5);
          
     this.storage.store("address_type",2)
     // ...end...
            this.router.navigate(['/homepage/addAddress']);
          }
        })
      }
      else if (shippingaddressadded>0  && billingaddressadded== 0) {
        Swal.fire({
          title: 'Warning',
          text: "Currently there is no billing address added in user profile. Do you want to add address?",

          showCancelButton: true,

          cancelButtonText: 'No',
          confirmButtonText: 'YES',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.storage.store("goingthroughgeneratepickup", 5);
          
     this.storage.store("address_type",1)
     // ...end...
            this.router.navigate(['/homepage/addAddress']);
          }
        })
      }
      else if (shippingaddressadded == 0 && billingaddressadded==0) {
        Swal.fire({
          title: 'Warning',
          text: "Currently there is no billing and shipping address added in user profile. Do you want to add address?",

          showCancelButton: true,

          cancelButtonText: 'No',
          confirmButtonText: 'YES',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.storage.store("goingthroughgeneratepickup", 5);
          
     this.storage.store("address_type",2)
     // ...end...
            this.router.navigate(['/homepage/addAddress']);
          }
        })
      }
      else{

     
        let formData = new FormData();
        if (this.kycForm.value.additional != "") {
          formData.append("additional_document", this.additional);
        }
        formData.append("gst_number", this.kycForm.value.gstnumber);
        formData.append("firm_name", this.kycForm.value.firmname);
        formData.append("pan_number", this.kycForm.value.pannumber);
        // <!-- address field removed by Kunal bansal on 26 may 2021 start -->
        // formData.append("address", this.kycForm.value.address);
        // end
        formData.append("pan_card", this.pancard);
        formData.append("cancelled_cheque", this.cheque);
        formData.append("bank_name", this.kycForm.value.bankname);
        formData.append("bank_ac_number", this.kycForm.value.acnumber);
        formData.append("ifsc_code", this.kycForm.value.ifsc);

        if(this.country=="UAE"){
          formData.append("gst_doc",this.vatcertificate);
          formData.append("iban_code",this.kycForm.value.iban);
          formData.append("swift_code",this.kycForm.value.swiftcode);
          formData.append("trn_number",this.kycForm.value.trnno);
        }
   
        this.authservice.formdata('UploadKYCDetails/', formData).subscribe((res: any) => {
          let response = res.json();
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            Swal.fire(response.message)
          }
          else if (response.status == 200) {
            Swal.fire(response.message)
            this.router.navigate(['/homepage/profile']);
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("Connect to the Internet")
          }
        )
      }
      }
    }
  }

  // Images only check added by Kunal bansal on 04 june 2021 start
  resetpan() {
    $("#panreset").val("");
  }
  resetcheque() {
    $("#chequereset").val("");
  }
  // end

  // <!-- pan and cheque upload fnctns added by Kunal banasl on 04 june 2021 start -->
  panupload(event: any, i) {
    const file = event.target.files[0];
    if (file.size / 1000 / 1000 > 2) {
      Swal.fire("Image size should not be greater than 2 Mb.")
    }
    // .webp support phase 3 new cr added by Kunal bansal on 09 march 2021 start
    else if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "image/webp") {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          //this.url = (<FileReader>event.target).result;
        }
        reader.readAsDataURL(event.target.files[0]);
      }
      this.pancard = event.target.files[0];
      console.log(this.pancard)
    }
    else {
      this.resetpan();
      Swal.fire("Please Upload images only");
      // commented by Kunal bansal on 25 may 2021

      // end
    }



  }

  tandc() {
    window.open("/homepage/TandC", "_blank");
  }

  chequeupload(event: any, i) {
    const file = event.target.files[0];
    if (file.size / 1000 / 1000 > 2) {
      Swal.fire("Image size should not be greater than 2 Mb.")
    }
    // .webp support phase 3 new cr added by Kunal bansal on 09 march 2021 start
    else if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "image/webp") {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          //this.url = (<FileReader>event.target).result;
        }
        reader.readAsDataURL(event.target.files[0]);
      }
      this.cheque = event.target.files[0];
    }
    else {
      this.resetcheque();
      Swal.fire("Please Upload images only")
      // commented by Kunal bansal on 25 may 2021

      // end
    }
  }

  // end


// added by Kunal bansal on 26 aug 2021 start
  resetdoc() {
    if(this.country=="UAE"){
      $("#vatreset").val("");
    }
    else{
      $("#docreset").val("");
    }
  
  }
  // ..end..
  
  vatcertficate(event: any, i) {
    const file = event.target.files[0];
    if (file.size / 1000 / 1000 > 2) {
      Swal.fire("Image or pdf size should not be greater than 2 Mb.")
    }
    // .webp support phase 3 new cr added by Kunal bansal on 09 march 2021 start
    else if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "image/webp" || file.type=="application/pdf")  {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          
          //this.url = (<FileReader>event.target).result;
        }
        reader.readAsDataURL(event.target.files[0]);
        this.vatcertificate = event.target.files[0];
      }
    }
    else {
      // added by Kunal bansal on 25 may 2021
      Swal.fire("File should be in png , jpg , webp or pdf format");
      this.resetdoc();
      // end
    }

  }


  readUrl(event: any, i) {
    const file = event.target.files[0];
    if (file.size / 1000 / 1000 > 2) {
      Swal.fire("Image or pdf size should not be greater than 2 Mb.")
    }
    // .webp support phase 3 new cr added by Kunal bansal on 09 march 2021 start
    else if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "image/webp" || file.type=="application/pdf")  {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          
          //this.url = (<FileReader>event.target).result;
        }
        reader.readAsDataURL(event.target.files[0]);
        this.additional = event.target.files[0];
      }
    }
    else {
      // added by Kunal bansal on 25 may 2021
      Swal.fire("File should be in png , jpg , webp or pdf format");
      this.resetdoc();
      // end
    }

    // if (i == 1) {
    //   this.pancard = event.target.files[0];
    // }
    // else if (i == 2) {
    //   this.cheque = event.target.files[0];
    // }
    // else if (i == 3) {
  
    // }
  }

}