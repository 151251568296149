import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
// Phase 5 new api common service created by KUnal bansal on 21 oct 2021 start
import { ApiserviceService } from '../Services/apiservice.service';
// ...end...
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth.service';
import { WindowRefService } from '../window-ref.service';
import { environment } from '../../environments/environment';
import { Currentdate } from '../Services/getcurrentdate';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { endsWith } from 'core-js/core/string';



@Component({
  selector: 'app-my-transactions',
  templateUrl: './my-transactions.component.html',
  styleUrls: ['./my-transactions.component.css']
})
export class MyTransactionsComponent implements OnInit {

  Entries: any = [];

  Paymentmode: any = [{ id: 1, "name": "Net Banking" }, { id: 2, "name": "Debit Card" }, { id: 3, "name": "Wallet" }]
  transactiontype: any = [{ id: 1, "name": "Order Placed" }, { id: 2, "name": "Order Refund" }, { id: 3, "name": "Gift Card" }, { id: 4, "name": "Cash Load" }, { id: 5, "name": "Part Payment" }]
  temp: number;
  dropdownSettings = {};
  walletbalance: number;

  Applieddatefrom = [];
  Applieddateto = [];
  pickupdatefrom: string = "";
  pickupdateto: string = "";
  tomindate: string = "";
  frommaxdate: string = "";
  tomaxdate: string = "";
  selectedItems2: any[];
  selectedItems3: any[];
  manualsearchfield: string;
  // phase 5 showing active filter labels dded by Kunal bansal on 28 oct 2021 start
  selectedpaymentmode = [];
  selectedtransactiontype = [];
  activelabels = [];

  loadwallet = true;

  order_id: any;
  rzp1: any;
  options = {};
  paymentmethod = {

  }
  tokentype: string;
  agenttoken: string;
  agentstorename: string;
  razorpayreplicamodes = [];
  closeResult: string;

  ordernumber: string;
  remarks: string="";
  amount: string;
  Balanceui: any;
  filteractive: boolean = false;

  country: string = 'India';

  walletsymbol:string="";

  // Dynamic payment mode price added by Kunal bansal on 06 dec 2021 start
  Dynamicpaymentmodeprices = {
    NetBanking: 0,
    Card: 0,
    Wallet: 0,
    UPI: 0,
    EMI: 0
  }
  Eolspecialcharge: number;
  // ...end...
  collapseuserdetail: number;
  useremail:string="";
  userphone:number;


  constructor(private ngZone: NgZone, public cd: ChangeDetectorRef, private current: Currentdate, private winRef: WindowRefService, private authservice: AuthService, private modalService: NgbModal, private trans: ApiserviceService, private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",

      labelKey: "name",
      classes: "font_drop",
      maxHeight: 200


    };
    this.useremail=sessionStorage.getItem("useremail");
    this.userphone=Number(sessionStorage.getItem("usephone"));
    this.country = sessionStorage.getItem('phase5country');
    // Phase 5 new api common service created by KUnal bansal on 11 nov 2021 start
    this.tokentype = sessionStorage.getItem("tokentype");
    this.agenttoken = sessionStorage.getItem("agenttoken");
    this.agentstorename = sessionStorage.getItem("agentstorename");
    if (this.tokentype == 'agenttoken') {
      this.viewtransactionhistory(this.agenttoken)
    }
    else {
      this.Fetchtransactions();
    }



    //  let paraopentag=this.purehtml[0].para.replace("<p>","");
    //  let paraclosetag=paraopentag.replace("</p>","");
    //  alert(paraclosetag)
    // ...end...


  }


  useropen(index) {
    if (this.collapseuserdetail == index) {
      this.collapseuserdetail = null;
    }
    else {
      this.collapseuserdetail = index;
    }

  }

  // phase 5 my wallet paymwnt scernario added by Kunal bansal on 12 nov 2021 start

  razorpayrepli(razorpayreplica) {

    if (this.loadwallet == false && (this.ordernumber == "" || this.ordernumber == undefined)) {
      Swal.fire("Order number is mandatory")
    }
    else if (this.amount == "" || this.amount == undefined) {
      Swal.fire("Please select all mandatory fields")
    }
    else {
      this.modalService.open(razorpayreplica, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {

        this.closeResult = `Dismissed `;
      });

      this.fetchbuyerlist();
    }

  }

  fetchbuyerlist() {
    this.razorpayreplicamodes = [];
    this.trans.getpaymentmodes().subscribe(res => {
      let response = res.json();
      this.spinner.hide();
      if (response.status == 200) {
        console.log(response);
        this.paymentmethod = response.result.paymentmethod;
        for (let h = 0; h < response.result.list.length; h++) {
          if (response.result.list[h].name == "Card" && response.result.list[h].enable == true) {
            // Dynamic payment mode price added by Kunal bansal on 06 dec 2021
            this.Dynamicpaymentmodeprices.Card = response.result.list[h].amount;
            this.razorpayreplicamodes.push({ "mode": "Card", "info": "Visa, MasterCard, RuPay & More", value: "card", amount: response.result.list[h].amount })
          }
          else if (response.result.list[h].name == "UPI" && response.result.list[h].enable == true) {
            this.Dynamicpaymentmodeprices.UPI = response.result.list[h].amount;
            this.razorpayreplicamodes.push({ "mode": "UPI / QR", "info": "Google Pay, PhonePe & more", value: "upi", amount: response.result.list[h].amount })
          }
          else if (response.result.list[h].name == "NetBanking" && response.result.list[h].enable == true) {
            this.Dynamicpaymentmodeprices.NetBanking = response.result.list[h].amount;
            this.razorpayreplicamodes.push({ "mode": "Netbanking", "info": "All Indian banks", value: "netbanking", amount: response.result.list[h].amount })
          }
          else if (response.result.list[h].name == "Wallet" && response.result.list[h].enable == true) {
            this.Dynamicpaymentmodeprices.Wallet = response.result.list[h].amount;
            this.razorpayreplicamodes.push({ "mode": "Wallet", "info": "Freecharge and PayZapp", value: "wallet", amount: response.result.list[h].amount })
          }
          else if (response.result.list[h].name == "EMI" && response.result.list[h].enable == true) {
            this.Dynamicpaymentmodeprices.EMI = response.result.list[h].amount;
            this.razorpayreplicamodes.push({ "mode": "EMI", "info": "EMI via Credit & Debit Cards", value: "emi", amount: response.result.list[h].amount })
          }
          else {

          }
        }
      }
      else if (response.status == 401) {
        Swal.fire(response.message)
      }
      else {
        Swal.fire(response.message);
      }

    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }


  // ................payment first step................
  // makepayment() {
  //   let body = {

  //     payment_flag:3
  //   }

  //   this.authservice.apipost('makePayment/', body).subscribe((res: any) => {
  //     let response = res.json();
  //     if (response.status == 400 || response.status == 500 || response.status == 100) {
  //       Swal.fire(response.message);
  //     }
  //     else if (response.status == 200) {
  //       this.order_id = response.order_id
  //       this.openrazorpaywindow();
  //     }
  //     else { Swal.fire(response.message); }
  //   }
  //     , err => {
  //       this.spinner.hide();
  //       Swal.fire("Connect to the Internet");
  //     }
  //   )
  // }

  // ................payment first step................
  openrazorpaywindow() {
    let percentamount=this.Eolspecialcharge/100;
    let further=Number(this.amount)*percentamount;

    this.authservice.apipost('getOrderId/', {
      amount: (Number(this.amount)+further) * 100,
      aversion: "1.0.1.5",

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message);
      }
      else if (response.status == 200) {

        this.options = {
          //"key": "rzp_test_Xl0iS8BgLClxsP",
          //"key":"rzp_live_ubK3anJmWUsySw",
          "key": environment.razor_key,
          "amount": Number(this.amount+further) * 100,
          "name": "Payment to EOL",
          "currency": "INR",
          // phase 5 razor pay dynamic payment mode handle from backend added by Kunal bansal on 13 oct 2021 start
          'method': this.paymentmethod,
          // ...end...
          // ........static text changed on 18 may 2022 start
          "prefill": {
            email: this.useremail,
            contact: this.userphone,
            //'method':'netbanking', 
          },
          // ...end....
          'handler': this.paymentCapture.bind(this),
          "order_id": response.response.Order_Id,
        }
        this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
        this.rzp1.open();
      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }

  // ................payment second and final step................
  paymentCapture(response) {

    // this.authservice.apipost('payment/', {
    //   razor_payment_id: response.razorpay_payment_id,
    //   order_id: this.order_id,
    //   razor_order_id: response.razorpay_order_id,
    // }).subscribe((res: any) => {
    //   let response = res.json();
    //   if (response.status == 400 || response.status == 500 || response.status == 100) {
    //     Swal.fire(response.message);
    //   }
    //   else if (response.status == 200) {
    //     Swal.fire(response.message);
    //   }
    //   else { Swal.fire("Server Down"); }
    // }
    //   , err => {
    //     this.spinner.hide();
    //     Swal.fire("Connect to the Internet");
    //   }
    // )

    var dateobj = new Date();
    let hours = dateobj.getHours();
    let minutes = dateobj.getMinutes();
    let currentime = hours + ":" + minutes;
    let currentdate;
    this.current.Getcurrentdate().map(res => {
      currentdate = res;
    })
    let body = {
      "money_type": this.loadwallet == true ? "pay_againts_wallet" : "pay_againts_order",
      "order_number": this.loadwallet == true ? "" : this.ordernumber,
      "amount": Number(this.amount),
      "remarks": this.remarks,
      "payment_mode": "Net Banking",
      "email": "",
      "phone": "",
      "transaction_details": "",
      "user_type": "self",
      "date": currentdate + " " + currentime,

    }

    this.trans.addmoneytowallet(body).subscribe(res => {
      let response = res.json();
      this.spinner.hide();
      if (response.status == 200) {
        this.modalService.dismissAll();
        Swal.fire(response.message);
        this.resetpopup();
        this.Fetchtransactions();
        //  location.reload();
        // this.fetchbuyerlist();
      }
      else if (response.status == 401) {
        Swal.fire(response.message)
      }
      else {
        Swal.fire(response.message);
      }

    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

  }
  // .....end......

  getorderdetailbynumber() {
    if (this.ordernumber == "" || this.ordernumber == undefined) {

    }
    else {
      let body = {
        "order_number": Number(this.ordernumber)
      }
      this.trans.getorderdetailbyordernumber(body).subscribe(res => {
        let response = res.json();
        this.spinner.hide();
        if (response.status == 200) {

          this.Balanceui = response.response;
          console.log(this.Balanceui)
        }
        else if (response.status == 401) {
          Swal.fire(response.message)
        }
        else {
          Swal.fire(response.message);
          this.ordernumber = "";
        }

      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }


  resetpopup() {
    this.amount = "";
    this.remarks = "";
    this.ordernumber = "";
  }



  resetpage() {
    location.reload();
  }

  closeall() {
    this.modalService.dismissAll();

  }

  radiochange(no) {
    if (no == 0) {
      this.loadwallet = true;
    }
    else {
      this.loadwallet = false;
    }
  }


  walletpopup(addmoney) {
    this.modalService.open(addmoney, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
    this.resetpopup();
    this.transactionfilterreset();
  }

  // phsae 5 new cr my transactions scenario added by Kunal bansal on 27 oct 2021 start
  Downloadcsvpdftransactions(type) {
    let daterange;
    if (this.filteractive == true) {
      daterange = this.activelabels[0];
    }
    else {
      daterange = "";
    }

    this.trans.Downloadcsvpdftransactions(type, daterange, this.tokentype, this.agenttoken);
  }
  // ...end...

  transactionfilterreset() {
    this.pickupdatefrom = "";
    this.pickupdateto = "";
    this.frommaxdate = "";
    this.tomindate = "";
    this.Applieddatefrom = [];
    this.Applieddateto = [];
    this.selectedItems2 = [];
    this.selectedItems3 = [];
  }

  // openlink(url){
  //   alert(url)
  //   window.open(url,"_blank")
  // }
  transactionfilter() {

    if (this.pickupdatefrom.length == 0 || this.pickupdateto.length == 0) {
      Swal.fire("Please select date")
    }
    else {
      // phase 5 showing active filter labels dded by Kunal bansal on 28 oct 2021 start
      this.selectedpaymentmode = [];
      this.selectedtransactiontype = [];

      if (this.selectedItems2 != undefined) {
        for (let v = 0; v < this.selectedItems2.length; v++) {
          this.selectedpaymentmode.push(this.selectedItems2[v].name)
        }
      }
      if (this.selectedItems3 != undefined) {
        for (let k = 0; k < this.selectedItems3.length; k++) {
          this.selectedtransactiontype.push(this.selectedItems3[k].name)
        }
      }

      // ...end...
      let body = {
        date_range: this.Applieddatefrom + "-" + this.Applieddateto,
        transaction_type: [],
        // payment_mode: this.selectedpaymentmode,
      }
      let check;
      if (this.tokentype == 'agenttoken') {
        check = 1;
      }
      else {
        check = 0;
      }
      this.trans.Searchtransactions(body, check, this.agenttoken).subscribe(res => {
        let response = res.json();
        this.spinner.hide();
        if (response.status == 200) {
          this.Entries = response.user_ledger_history;
          this.walletbalance = response.total_balance_amount;
         
          // phase 5 showing active filter labels dded by Kunal bansal on 28 oct 2021 start
          this.activelabels = [];
          this.activelabels.push(this.Applieddatefrom + "-" + this.Applieddateto);
          // for (let z = 0; z < this.selectedpaymentmode.length; z++) {
          //   this.activelabels.push(this.selectedpaymentmode[z])
          // }
          for (let y = 0; y < this.selectedtransactiontype.length; y++) {
            this.activelabels.push(this.selectedtransactiontype[y])
          }
          //  ...end...
          this.transactionfilterreset();
          this.filteractive = true;
          this.modalService.dismissAll();
        }
        else if (response.status == 401) {
          Swal.fire(response.message)
        }
        else {
          Swal.fire(response.message);
        }

      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }

  }

  manualsearch() {
    if (this.manualsearchfield == "" || this.manualsearchfield == undefined) {
      Swal.fire("Search field empty")
    }
    else {
      let body = {
        search_string: this.manualsearchfield
      }
      let check;
      if (this.tokentype == 'agenttoken') {
        check = 1;
      }
      else {
        check = 0;
      }
      this.trans.Searchtransactions(body, check, this.agenttoken).subscribe(res => {
        let response = res.json();
        this.spinner.hide();
        if (response.status == 200) {
          this.manualsearchfield = "";
          this.Entries = response.user_ledger_history;
          this.walletbalance = response.total_balance_amount;
        }
        else if (response.status == 401) {
          Swal.fire(response.message)
        }
        else {
          Swal.fire(response.message);
        }

      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }

  }

  viewtransactionhistory(token) {
    this.trans.specificusertransaction(token).subscribe(res => {
      let response = res.json();
      this.spinner.hide();
      if (response.status == 200) {
        this.Entries = response.user_ledger_history;
        this.walletbalance = response.total_balance_amount;
        this.walletsymbol=response.total_balance_amount_type;
      }
      else if (response.status == 401) {
        Swal.fire(response.message)
      }
      else {
        Swal.fire(response.message);
      }

    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  // Phase 5 new api common service created by KUnal bansal on 21 oct 2021 start
  Fetchtransactions() {
    this.trans.FetchTransactions().subscribe(res => {
      let response = res.json();
      this.spinner.hide();
      if (response.status == 200) {
        this.ngZone.run(() => {
          this.Entries = response.user_ledger_history;
          this.walletbalance = response.total_balance_amount;
          this.walletsymbol=response.total_balance_amount_type;
    
          console.log(this.walletbalance)
        });


      }
      else if (response.status == 401) {
        Swal.fire(response.message)
      }
      else {
        Swal.fire(response.message);
      }

    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

  }
  // ...end...

  openpaymentcsvpdf(i) {
    if (this.temp == i) {
      this.temp = undefined;
    }
    else {


      this.temp = 1;

    }
  }

  opencategorymodal(shopbycategory) {
    var dateobj = new Date();
    var month = dateobj.getUTCMonth() + 1;
    var date = dateobj.getUTCDate();
    var year = dateobj.getUTCFullYear();
    if (month.toString().length == 1 && date.toString().length == 2) {
      var fulldate = year + "-" + "0" + month + "-" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else if (month.toString().length == 2 && date.toString().length == 1) {
      var fulldate = year + "-" + month + "-" + "0" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else if (month.toString().length == 1 && date.toString().length == 1) {
      var fulldate = year + "-" + "0" + month + "-" + "0" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else {
      var fulldate = year + "-" + month + "-" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }

    this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
  }

  fromdate(event) {

    var fromdate = event.target.value.split('-').reverse().join('/');
    this.Applieddatefrom = fromdate;
    this.tomindate = event.target.value;

  }

  todate(event) {
    if (this.pickupdatefrom.length == 0) {
      Swal.fire("please select upper date first")
      this.pickupdateto = "";
    }
    else {
    }
    var todate = event.target.value.split('-').reverse().join('/');
    this.Applieddateto = todate;
  }

  // phase 6 code shrtening and optimization added by kunal bansal 25 may 2022 start

  selectpayment(event:any){
    // this.paymentmethod=event;
    // this.openrazorpaywindow();
    this.Eolspecialcharge=event.Eolspecialcharge;
    // this.selectedpaymodeforwallet=event.selectedpaymodeforwallet;
    this.paymentmethod = event.paymentmethod; 
    console.log(event);
    this.openrazorpaywindow();
  }

}
