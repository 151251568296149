import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import Swal from 'sweetalert2';

import { Uaepoboxes } from '../../app/Services/Uaepoboxes.service';

// <!-- .....Phase 5 new cr Country selection module added by Kunal bansal on 17 aug 2021 start........

// ...end...

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

export class SignupComponent implements OnInit {
  model: any = {};
  temp: number;
  temp2: number = 0;
  category: any[];
  childcategory = [];
  labelling = [];

  untickparent: number = 5000;
  // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 12 aug 2021 start........ -->
  borderactive: number = 0;
  selectedcountry: string = "India";
  uaecountry: string;
  ponumber: number;
  // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 13 aug 2021 start........
  uaecities: any = []
  // ......end.......
  // <!-- .....Phase 5 new cr Country selection module added by Kunal bansal on 17 aug 2021 start........
  tradelicense: File = null;
  additionaldoc: File = null;

  // <!-- .....Phase 5 new cr Country selection module added by Kunal bansal on 18 aug 2021 start........
  // ........customvalidations........
  uaelocationcities = [];

  Freezonelisting = [];
  uaecountries = [];



  constructor(private uae: Uaepoboxes, private pobox: Uaepoboxes, private storage: SessionStorageService, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService, private modalService: NgbModal) { }

  ngOnInit() {
    this.model.country = 'India';
    this.storage.clear("signupphone");
    this.storage.clear("signupdata");

    $(document).ready(function () {
      $('form').on('blur', 'input[type=number]', function (e) {
        $(this).off('mousewheel.disableScroll')
      })

      $("input[type=number]").keypress(function (evt) {
        if ((evt.which < 48 || evt.which > 57) || ($(this).val().length == 10)) {
          if (evt.which == 8 || evt.which == 0) { }
          else { evt.preventDefault(); }
        }
      })
    })
    this.authservice.apipost('CategoryList/', {
      "flag": "filtercatgory"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))
        this.category = response.response;

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end
    this.getfreezonelisting();
    //phase 5 added by Kunal bansal on 02 nov 2021 due to new scenario of pobox autofetch reklation break and city fetched based on country
    this.gettalluaecountries();

  }

  formdata() {

  }

  // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 12 aug 2021 start........ -->

  borderactivate(a) {
    if (a == 1) {
      console.log('focus');
      this.borderactive = 1;
    }
    else {
      console.log("focus out");
      this.borderactive = 0;
    }
  }

  changecountry(event) {
    console.log(event.target.value)
    this.selectedcountry = event.target.value;
    // <!-- .....Phase 5 new cr Country selection module added by Kunal bansal on 17 aug 2021 start........
    this.model.country = event.target.value;
    // ...end...
    // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 aug 2021 start........ -->
    this.model.state = '';
    this.model.pincode = '';
    this.uaecities = [];
    this.model.city = '';
    if (event.target.value == 'UAE') {
      this.model.location_type = "";
      this.uaelocationcities = [{ "name": "Please Select Location", "value": "" }, { "name": "Freezone", "value": "Freezone" }, { "name": "Mainland", "value": "Mainland" }];
    }


  }

  // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 17 aug 2021 start........ -->

  locationtype(event) {
    this.model.uaeselectedlocation = "";
    this.model.location_type = event.target.value;
  }

  resetdoc() {
    $("#docreset").val("");
  }
  resetadddoc() {
    $("#adddocreset").val("");
  }

  Tradelicense(event: any) {

    const file = event.target.files[0];
    if (file.size / 1000 / 1000 > 2) {
      Swal.fire("File size should not be greater than 2 Mb.")
    }
    // .webp support phase 3 new cr added by Kunal bansal on 09 march 2021 start
    else if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "application/pdf") {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          //this.url = (<FileReader>event.target).result;
        }
        reader.readAsDataURL(event.target.files[0]);
      }
      this.model.tradelicense = event.target.files[0];

    }
    else {
      this.resetdoc();
      Swal.fire("Please Upload images or document only");
      // commented by Kunal bansal on 25 may 2021

      // end
    }
  }

  aditionaldoc(event: any) {
    const file = event.target.files[0];
    if (file.size / 1000 / 1000 > 2) {
      Swal.fire("File size should not be greater than 2 Mb.")
    }
    // .webp support phase 3 new cr added by Kunal bansal on 09 march 2021 start
    else if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "application/pdf") {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          //this.url = (<FileReader>event.target).result;
        }
        reader.readAsDataURL(event.target.files[0]);
      }
      this.model.additional_doc = event.target.files[0];
      // this.model.additional_doc=this.tradelicense;
    }
    else {
      this.resetadddoc();
      Swal.fire("Please Upload images or document only");
      // commented by Kunal bansal on 25 may 2021

      // end
    }
  }
  // ...end...

  // phase 5 new cr uae freezone and mainland cities module added by Kunal bansal on 29 oct 2021 start
  getfreezonelisting() {
    try {
      this.uae.getfreezonelisting().map(res => {

        this.Freezonelisting = res.response;
        console.log(this.Freezonelisting);
      })
    }
    catch (err) {
      console.log(err)
    }
  }
  // ...end...




  //phase 5 added by Kunal bansal on 02 nov 2021 due to new scenario of pobox autofetch reklation break and city fetched based on country
  gettalluaecountries() {

    try {
      this.pobox.getallcountries().map(res => {

        if (res.status == 200) {
          console.log(res.response);

          for (let n = 0; n < res.response.length; n++) {
            this.uaecountries.push(res.response[n].country)
          }
          console.log(this.uaecountries)
        }
        else {

        }
      })
    }
    catch (err) {
      // Swal.fire("Enter valid Pobox number");
      console.log(err)
      // this.model.state = '';
      // // this.uaecities = [];
    }

  }

  selectuaecountry(event) {
    this.model.city = '';
    try {
      this.pobox.getcitybycountry(event.target.value).map(res => {

        if (res.status == 200) {
          this.uaecities = res.response.cities;
        }
        else {

        }
      })
    }
    catch (err) {
      Swal.fire("Enter valid Pobox number");
      this.model.state = '';
      this.uaecities = [];
    }
  }
  // ...end...

  changecity(event) {

    if (event.target.value == "Invalid") {
      this.model.city = '';


    }
    else {
      this.model.city = event.target.value;
    }

  }

  getpobox() {
    if (this.model.pincode == undefined || this.model.pincode == "") {
      // Swal.fire('Enter valid Po Box Number')
    }
    else {
      try {
        this.pobox.Getponumber(this.model.pincode).map(res => {

          if (res.status == 200) {
            console.log(res.response)
            // commented by Kunal bansal on 02 nov 2021 due to new scenario of pobox autofetch reklation break and city fetched based on country
            // this.model.state = res.response.country;
            // this.uaecities = res.response.cities;
          }
          else {

          }
        })
      }
      catch (err) {
        Swal.fire("Enter valid Pobox number");
        this.model.pincode = '';
        // this.model.city = '';
        // this.uaecities = [];
      }

    }
  }

  // ..............end.............

  // Agent code validate api implemented on 17 march 2021 by Kunal bansal start
  validateagent() {
    if (this.model.agent_code.trim() == "" || this.model.agent_code.length == 0) {
      // Swal.fire("agent code not be empty");
      this.model.agent_code = "";

    }
    else {
      this.authservice.apipost('validateAgentCode/', {
        agent_code: this.model.agent_code

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);
          this.model.agent_code = '';
        }
        else if (response.status == 200) {
          // Swal.fire(response.message)
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }
  // end

  // <!-- New phase 3 select category prefrence on signup added by kunal bansal on 10 march 2021 start -->
  opencatpopup(shopbycategory) {

    this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
    // this.childcategory = [];
    // this.labelling=[];
    this.temp = undefined;
    // <!-- New phase 3 cr categoryapi added by kunal bansal on 10 march 2021 start -->


  }
  // end

  // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 01 march 2021 start -->
  agent(event) {
    this.model["agent_code"] = event.target.value;
  }
  // end

  closeall() {
    this.modalService.dismissAll();
  }



  onSubmit(valid) {
    // form data validation checked functionality added by Kunal banasl on 24 dec 2021 start
    let status = valid.form.status;
    // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 01 march 2021 start -->
    // this.model["agent_code"] = "";
    // end
    console.log(status)
    var email = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    var filter = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{8,}$/;
    var name = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    if (status == "INVALID") {
      Swal.fire("Please fill all the Mandatory fields")
    }
    // ...end...
    else if (email.test(this.model.email.trim()) == false) {
      Swal.fire('Please enter a valid email id');

    }

    else if (name.test(this.model.name.trim()) == false) {
      Swal.fire("Please enter a valid Full name.")
    }
    else if (JSON.stringify(this.model.phone).length < 10 || JSON.stringify(this.model.phone).length > 10) {
      Swal.fire("Please enter valid Mobile number.")
    }
    else if (filter.test(this.model.password) == false) {
      Swal.fire("Your password must have 8 or more characters and include upper and lowercase letters, numbers and a special character")
    }
    else if (this.model.password != this.model.confirmpassword) {
      Swal.fire("Password and Confirm password should be same ")
    }
    else if ($("#customCheck1").prop("checked") == false) {
      Swal.fire("Please accept the Terms and Conditions.");
    }
    else if (this.childcategory.length == 0) {
      Swal.fire("Please select at least one category")
    }
    else if (this.selectedcountry == 'UAE' && this.model.tradelicense == undefined) {
      Swal.fire("Trade License Empty")
    }

    // end
    else {
      if ($("#customCheck2").prop("checked") == false) {
        this.model.notify_allow = 0
      }
      else {
        this.model.notify_allow = 1
      }
      //console.log(this.model)
      this.model.category = this.childcategory;
      this.storage.store("signupverifycountry", this.selectedcountry);
      console.log(this.model)
      // ..............raw data form commented by Kunal bansal on 17 aug 2021 to add phase 5 country module with formdata length......
      // this.authservice.apipost('registerUser/', {
      //   email: this.model.email,
      //   phone: this.model.phone,
      //   sms_otp: "",
      //   email_otp: "",
      //   agent_code: this.model.agent_code,
      //   category: this.childcategory,
      //   trade_license:this.tradelicense
      // }).subscribe((res: any) => {
      //   let response = res.json();
      //   if (response.status == 400 || response.status == 500 || response.status == 100) {
      //     Swal.fire(response.message)
      //   }
      //   else if (response.status == 200) {
      //     this.storage.store("signupphone", this.model.phone);
      //     this.storage.store("signupdata", this.model);

      //     this.router.navigate(['/homepage/signupverify']);
      //   }
      //   else { Swal.fire("Server Down") }
      // }
      //   , err => {
      //     this.spinner.hide();
      //     Swal.fire("Connect to the Internet")
      //   }
      // )
      // <!-- .....Phase 5 new cr Country selection module raw data form converterted to formdata added by Kunal bansal on 17 aug 2021 start........

      let formData = new FormData();
      formData.append("email", this.model.email),
        formData.append('phone', this.model.phone),
        formData.append('sms_otp', ''),
        formData.append('email_otp', ''),
        formData.append('agent_code', this.model.agent_code),
        // formData.append('category', JSON.stringify(this.childcategory)),
        formData.append('country', this.selectedcountry),


        this.authservice.formdata('registerUser/', formData).subscribe((res: any) => {
          let response = res.json();
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            Swal.fire(response.message)
          }
          else if (response.status == 200) {
            this.storage.store("signupphone", this.model.phone);
            this.storage.store("signupdata", this.model);
            this.storage.store("signupotptiming", response.expiry_time);
            this.storage.store("childcategorystore", this.childcategory);
            this.router.navigate(['/homepage/signupverify']);
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("Connect to the Internet")
          }
        )
    }
  }

  clear() {
    $(".form__widh_icons").trigger("reset");
  }

  getpincode() {
    if (JSON.stringify(this.model.pincode).length < 6 || JSON.stringify(this.model.pincode).length > 6) {
      this.model.state = "";
          this.model.city ="";
          this.model.pincode=""
      Swal.fire({
        title: 'Please enter a valid Pincode',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        this.model.state = "";
          this.model.city ="";
          this.model.pincode=""
        // if (result.value) {
        //   $('input[name ="pincode"]').select();
        // }
      })
    }
    else {
      this.authservice.apipost('getPincode/', {
        "pincode": JSON.stringify(this.model.pincode)
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500) {
          this.model.state = "";
          this.model.city ="";
          this.model.pincode=""
          Swal.fire(response.message)
        }
        else if (response.status == 100) {
          Swal.fire('No State City found linked to your pincode , Please try another pincode')
        }
        else if (response.status == 200) {
          this.model.state = response.response.state;
          this.model.city = response.response.city;
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }

  tandc() {
    window.open("/homepage/TandC", "_blank");
  }

  emitlabelling(event:any){
    console.log(event);
    this.childcategory=event.childcategory;
    this.labelling=event.labels;
  }

}