import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-sellrender',
  templateUrl: './sellrender.component.html',
  styleUrls: ['./sellrender.component.css']
})
export class SellrenderComponent implements OnInit {
  @Input() selllist:any=[];
  @Input() catid:number;
  @Input() tab:number;

  constructor(private storage: SessionStorageService,private router:Router) { }

  ngOnInit() {
  }

  productdetail(id) {
    this.storage.store("pdid", id);
    this.storage.store('catid', this.catid)
    this.storage.store('tabdetail', this.tab)
    this.storage.store("ptype", 1);
    this.router.navigate(['/homepage/productdetail'], { queryParams: { pid: id, catid: this.catid, ptype: this.tab } });
  }

}
