import { HttpClient } from '@angular/common/http';
import { Component, OnInit , ElementRef,ViewChild} from '@angular/core';
import { IoTThingsGraph } from 'aws-sdk';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageService } from 'ngx-webstorage';
import { AuthService } from 'src/app/auth.service';
import { Currentdate } from 'src/app/Services/getcurrentdate';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-seller-ledger',
  templateUrl: './seller-ledger.component.html',
  styleUrls: ['./seller-ledger.component.css']
})
export class SellerLedgerComponent implements OnInit {

  ledgerlisting: any = [];
  currentpage: number = 1;
  totallength: number = 2;
  page: number = 1;
  userid: number = 0;
  //  phase 6 seller ledger module added by kunal bansal on 27 april 2022 start
  total_outstading: number;
  total_payment: number;
  total_recieve: number;
  // ...end..,.,
  //  phase 6 seller ledger module added by kunal bansal on 28 april 2022 start
  searchdisabled: boolean = false;
  datedisabled: boolean = false;

  // ...............api params..................
  fromdate: string = "";
  todate: string = "";
  searchstring: string = "";
  todaydate:any="";

  storename:string="";
  @ViewChild('downloadbind') downloadbind; 
 

  constructor(private currentdate:Currentdate,private storage: SessionStorageService, private http: HttpClient, private service: AuthService, private convertdate: Currentdate, private spinner: NgxSpinnerService) { }

  ngOnInit() {
   this.todaydate=this.currentdate.Getcurrentdate()

    // end
    console.log(this.todaydate)
    this.userid = this.storage.retrieve('userid')

    this.Defaultlisting();
  }

  // phase 6 seller ledger module added by kunal bansal on 28 april 2022 start\
  pagechange(event) {
    this.page=event;
    let body;
    if (this.datedisabled == true) {
      body = {
        // offset: this.page,
        search_string: this.searchstring,
        date_range: ''
      }
      this.paginateapi(body)
    }
    else if (this.searchdisabled == true) {
      body = {
        // offset:this.page,
        search_string: '',
        date_range: `${this.convertdate.convertdate(this.fromdate)}-${this.convertdate.convertdate(this.todate)}`
      }
      this.paginateapi(body)
    }
    else {
    this.Defaultlisting();
    }

  }

  paginateapi(body) {
    this.service.apipost(`seller_ledger_records/?offset=${this.page}`, body).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 500 || response.status == 100) {
        Swal.fire(response.message);
        this.spinner.hide();
      }
      else if (response.status == 200) {
        console.log(response);
        this.ledgerlisting = response.records;
        this.totallength = response.total_count;
        this.total_outstading = response.total_outstading;
        this.total_payment = response.total_payment;
        this.total_recieve = response.total_recieve;
        console.log(this.ledgerlisting)

      }
      else {
        Swal.fire(response.message)
        this.spinner.hide();
      }

    })
  }

  // ....end.....

  // phase 6 seller ledger module added by kunal bansal on 27 april 2022 start\

  Defaultlisting() {

    this.service.apiget(`seller_ledger_records/?offset=${this.page}`).subscribe((res: any) => {

      let response = res.json();
      if (response.status == 500 || response.status == 100) {
        Swal.fire(response.message);
        this.spinner.hide();
      }
      else if (response.status == 200) {
        this.ledgerlisting = response.records;
        this.storename=response.store_name;
        this.totallength = response.total_count
        this.total_outstading = response.total_outstading
        this.total_payment = response.total_payment
        this.total_recieve = response.total_recieve
        console.log(this.ledgerlisting)

      }
      else {
        Swal.fire(response.message)
        this.spinner.hide();
      }

    }
    )
  }


  // phase 6 seller ledger module added by kunal bansal on 27 april 2022 start

  downloadfilterdata(event) {
    
    if (this.fromdate == "" && this.todate== "" && this.searchstring.trim() != "") {
      if (event.target.value == 'csv') {
        window.open(`${environment.baseurl}seller_ledger_report/?user_id=${this.userid}&rtype=csv&search_string=${this.searchstring}`)
      }
      else {
        window.open(`${environment.baseurl}seller_ledger_report/?user_id=${this.userid}&rtype=pdf&search_string=${this.searchstring}`)
      }
    }
    else if (this.fromdate != "" && this.todate != "" && this.searchstring.trim() == "") {
      // console.log(`${environment.baseurl}seller_ledger_report/?userid=${this.userid}&rtype=csv&date_range=${this.convertdate.convertdate(this.fromdate)}-${this.convertdate.convertdate(this.todate)}&search_string=`)
      if (event.target.value == 'csv') {
        window.open(`${environment.baseurl}seller_ledger_report/?user_id=${this.userid}&rtype=csv&date_range=${this.convertdate.convertdate(this.fromdate)}-${this.convertdate.convertdate(this.todate)}&search_string=`)
      }
      else {
        window.open(`${environment.baseurl}seller_ledger_report/?user_id=${this.userid}&rtype=pdf&date_range=${this.convertdate.convertdate(this.fromdate)}-${this.convertdate.convertdate(this.todate)}&search_string=`)
      }
    }
    else if (this.fromdate != "" && this.todate != "" && this.searchstring.trim() != "") {
      if (event.target.value == 'csv') {
        window.open(`${environment.baseurl}seller_ledger_report/?user_id=${this.userid}&rtype=csv&date_range=${this.convertdate.convertdate(this.fromdate)}-${this.convertdate.convertdate(this.todate)}&search_string=${this.searchstring}`)
      }
      else {
        window.open(`${environment.baseurl}seller_ledger_report/?user_id=${this.userid}&rtype=pdf&date_range=${this.convertdate.convertdate(this.fromdate)}-${this.convertdate.convertdate(this.todate)}&search_string=${this.searchstring}`)
      }
    }

    else {
      if (event.target.value == 'csv') {
        window.open(`${environment.baseurl}seller_ledger_report/?user_id=${this.userid}&rtype=csv&date_range=&search_string=`)
      }
      else {
        window.open(`${environment.baseurl}seller_ledger_report/?user_id=${this.userid}&rtype=pdf&date_range=&search_string=`)
      }
    }
    event.target.value = ""

  }

  // phase 6 seller ledger module added by kunal bansal on 27 april 2022 start

  downloadall(event) {
   
    if (event.target.value == 'csv') {
      window.open(`${environment.baseurl}seller_ledger_report/?user_id=${this.userid}&rtype=csv`)
    }
    else {
      window.open(`${environment.baseurl}seller_ledger_report/?user_id=${this.userid}&rtype=pdf`)
    }
    event.target.value = ""
  }


  // phase 6 seller ledger module added by kunal bansal on 27 april 2022 start

  filtersellerrecord(value) {

    if (value == 0) {
      if(this.fromdate==""){
       
      }
      else{
        this.searchstring = "";
        this.searchdisabled = true;
      // dd showing in date alternate added by Kunal bansal on 06 july 2022 start
        this.todate=this.todaydate;
        this.page=1;
        let body = {
          search_string: "",
          date_range: `${this.convertdate.convertdate(this.fromdate)}-${this.convertdate.convertdate(this.todate)}`
          , offset: this.page
        }
        this.paginateapi(body)
        // ..........end............
      }
      
    }
    else if (value == 1) {
      if(this.todate==""){
    
    }
    else{
      this.searchstring = "";
      this.searchdisabled = true;
      this.page=1;
      if(this.fromdate==""){
        Swal.fire('Please enter from date First')
        this.todate="";
      }
    }
    }
    else {
      if(this.searchstring.trim()==""){

      } 
      else{
        this.datedisabled = true;
        this.page=1;
        this.fromdate = "";
        this.todate = ""
      }
     
    }

    if (this.fromdate== "" && this.todate == "" && this.searchstring.trim() == "") {

    }
    else if((this.fromdate != "" && this.todate != "" ) && this.searchstring.trim() == ""){
      let body = {
        search_string: "",
        date_range: `${this.convertdate.convertdate(this.fromdate)}-${this.convertdate.convertdate(this.todate)}`
        , offset: this.page
      }
      this.paginateapi(body)
  

    }
    else if((this.fromdate == "" && this.todate == "" ) && this.searchstring.trim() != ""){
      let body = {
        search_string: this.searchstring,
        date_range: ""
        , offset: this.page
      }
      this.paginateapi(body)
    }
    else if((this.fromdate != "" || this.todate != "")  && this.searchstring.trim() == ""){
    
    }
    else {
  
    }

  }

  resetparams() {
    this.fromdate = "";
    this.todate = "";
    this.searchstring = "";
    this.datedisabled = false;
    this.searchdisabled = false;
    this.page = 1;
    this.Defaultlisting();
  }


  

  // openSelect(){
  //   let openStatus = false;
  // openStatus = !openStatus;
  // console.log(this.downloadbind.nativeElement)
  // }
  
  // handleChange() {
  //  alert('element slected ... closed');
  //  this.openSelect();
  //  }

}